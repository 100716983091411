import * as amplitude from "@amplitude/analytics-browser"
import Intercom, { shutdown, trackEvent } from "@intercom/messenger-js-sdk"
import config from "../config"

// Analytics Service was created so that events can be easily passed on
// and sent to multiple analytics providers if needed.

amplitude.init(config.amplitudeKey)

Intercom({
  app_id: config.intercomAppId,
})

export function loginAnalyticsUser(user: any, payload?: any) {
  amplitude.setUserId(`${user.analytics_id}`)
  Intercom({
    app_id: config.intercomAppId,
    user_id: `${user.id}`,
    email: user.email,
    name: user.name,
  })
}

export function logoutAnalyticsUser() {
  amplitude.setUserId(undefined)
  shutdown()
}

export function logEvent(name: string, payload?: any) {
  amplitude.logEvent(name, payload)
  trackEvent(name, payload)
}

export function getAmplitudeDeviceId() {
  amplitude.getDeviceId()
}
