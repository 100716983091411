import { Box, Typography, CircularProgress, Button } from "@mui/material"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { scopes } from "../../scopes"
import styles from "./GuestListsScreen.module.css"
import useGetAttendanceItems from "./hooks/useGetAttendanceItems"
import AttendanceItemDialog from "./components/AttendanceItemDialog"
import AttendanceItemTable from "./components/AttendanceItemTable"
import { logEvent } from "../../utils/analytics-service"
import { useParams } from "react-router-dom"

interface Params {
  organizationId?: string
}

const AttendanceScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const [createAttendanceItemOpen, setCreateAttendanceItemOpen] =
    useState(false)

  const {
    getAttendanceItems,
    paginationData,
    attendanceItems,
    loading: attendanceItemsLoading,
    error: attendanceItemsError,
  } = useGetAttendanceItems()

  useEffect(() => {
    const fetchData = async () => {
      await getAttendanceItems(Number(organizationId))
    }

    fetchData()
  }, [])

  if (attendanceItemsLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <>
      <ScreenContainer requiresScope={scopes.organization.attendanceItems.read}>
        <Box
          className={styles.createButtonContainer}
          onClick={() => {
            logEvent("attendance_create_attendance_item_button_clicked")
            setCreateAttendanceItemOpen(true)
          }}
        >
          <AddButton className="className" />
        </Box>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            mb: "24px",
          }}
          className={styles.guestListsScreen}
        >
          {attendanceItemsError && (
            <ErrorComponent error={attendanceItemsError} />
          )}
          {attendanceItemsLoading && <CircularProgress />}
          {attendanceItems.length === 0 ? (
            <>
              <Typography variant="h5">
                <b>No attendance items found</b>
              </Typography>
              <Typography variant="body1">
                Create an attendance item to track member's attendance at events
              </Typography>
              <Button
                onClick={() => {
                  logEvent("attendance_create_attendance_item_button_clicked")
                  setCreateAttendanceItemOpen(true)
                }}
                variant="contained"
                style={{ marginTop: "10px", cursor: "pointer" }}
              >
                Create Attendance Item
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h3" className={styles.title}>
                Attendance
              </Typography>
              <AttendanceItemTable
                attendanceItems={attendanceItems}
                paginationData={paginationData}
                organizationId={organizationId}
              />
            </>
          )}
        </Box>
        <AttendanceItemDialog
          isOpen={createAttendanceItemOpen}
          close={() => setCreateAttendanceItemOpen(false)}
          onSuccess={() => getAttendanceItems(Number(organizationId))}
          organizationId={organizationId}
        />
      </ScreenContainer>
    </>
  )
}

export default AttendanceScreen
