import React, { FunctionComponent, useEffect } from "react"
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { useParams } from "react-router-dom"
import styles from "../GuestLists/GuestListDetailsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import useGetAnnouncementViews from "./hooks/useGetAnnouncementViews"
import { scopes } from "../../scopes"
import AnnouncementViewRow from "./components/AnnouncementViewRow"
import { logEvent } from "../../utils/analytics-service"

type Params = {
  organizationId?: string
  announcementId?: string
}

const AnnouncementViewsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  const announcementId = Number(params.announcementId)

  const navigate = useNavigate()

  const {
    getAnnouncementViews,
    announcementViews,
    loading: announcementViewsLoading,
    error: announcementError,
  } = useGetAnnouncementViews()

  useEffect(() => {
    const fetchData = async () => {
      await getAnnouncementViews(Number(organizationId), announcementId)
    }

    logEvent("announcement_views_viewed", {
      id: announcementId,
    })

    fetchData()
  }, [])

  if (announcementViewsLoading) {
    return (
      <ScreenContainer
        requiresScope={scopes.organization.announcement.views.read}
      >
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!announcementViews) {
    return (
      <ScreenContainer
        title={"Announcement views not found"}
        requiresScope={scopes.organization.announcement.views.read}
      ></ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      requiresScope={scopes.organization.announcement.views.read}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginRight: 2,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(
              `/organizations/${organizationId}/announcements/${announcementId}`
            )
          }
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Box>
        <Box className={styles.name}>Announcement Views</Box>
      </Box>
      <Box>
        <Table component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Views</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {announcementViews.map((announcementView) => (
                <AnnouncementViewRow
                  key={announcementView.id}
                  announcementView={announcementView}
                />
              ))}
            </TableBody>
          </Table>
        </Table>
      </Box>
    </ScreenContainer>
  )
}

export default AnnouncementViewsScreen
