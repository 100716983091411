import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { GuestList } from "../../../types"
import GuestListRow from "./GuestListRow"

interface Props {
  guestLists: GuestList[]
  paginationData: PaginationData
  organizationId: number
}

const getRows = (guestLists: GuestList[], organizationId: number) => {
  return guestLists.map((guestList, index) => {
    return (
      <GuestListRow
        key={index}
        guestList={guestList}
        organizationId={organizationId}
      />
    )
  })
}

const StudyHoursTable = ({
  guestLists,
  paginationData,
  organizationId,
}: Props) => {
  const columns: string[] = ["Name", "Max Guests", "Visible", "Editing Enabled"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(guestLists, organizationId)}
      paginationOptions={paginationData}
    />
  )
}

export default StudyHoursTable
