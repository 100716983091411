import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AnnouncementView } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetAnnouncementViews = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<AnnouncementView[]>()

  const request = useCallback(
    (
      organizationId: number,
      announcementId: number
    ): Promise<AnnouncementView[]> => {
      return makeRequest<AnnouncementView[]>(
        `${config.apiUrl}/v1/organizations/${organizationId}/announcements/${announcementId}/views`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getAnnouncementViews = useCallback(
    async (organizationId: number, announcementId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, announcementId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getAnnouncementViews,
    announcementViews: data,
  }
}

export default useGetAnnouncementViews
