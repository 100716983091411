import React, { useEffect } from "react"
import { Box, Button, Typography } from "@mui/material"
import useGetHouseById from "../../screens/Organizations/hooks/useGetHouseById"

const ForbiddenPage = () => {
  const { getHouseById, loading } = useGetHouseById()
  const activeOrganization = localStorage.getItem("activeOrganization")

  useEffect(() => {
    const activeOrganization = localStorage.getItem("activeOrganization")

    const fetchOrganization = async () => {
      // Extract the organizationId from the URL path
      const match = window.location.pathname.match(/\/organizations\/(\d+)/)
      const organizationId = match ? match[1] : null

      console.log("Extracted organizationId:", organizationId)

      // If the organizationId is in the URL, set it as the active organization
      if (organizationId && !activeOrganization) {
        try {
          const house = await getHouseById(Number(organizationId))

          if (house) {
            localStorage.setItem("activeOrganizationId", organizationId)
            localStorage.setItem("activeOrganization", JSON.stringify(house))
            window.location.reload()
          }
        } catch (error) {
          console.error("Failed to fetch organization:", error)
        }
      }
    }

    fetchOrganization()
  }, [])

  if (!loading) {
    return (
      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="body1">
          You do not have access to this page. If you think this is an error,
          make sure you are accessing the correct organization and have
          permissions to perform this action. You currently have
          <b>
            {" "}
            {(activeOrganization && JSON.parse(activeOrganization).name) ||
              "no organization"}
          </b>{" "}
          active.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href = "/"
          }}
        >
          Go to Dashboard
        </Button>
      </Box>
    )
  }

  return null
}

export default ForbiddenPage
