import React, { FunctionComponent } from "react"
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from "@mui/material"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useUpdateStudyHoursPeriod from "../hooks/useUpdateStudyHoursPeriod"

interface Props {
  isOpen: boolean
  close: () => void
  currentPeriod: string
  onSuccess?: () => void
  organizationId: number
}

const EditPeriodDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  currentPeriod,
  onSuccess,
  organizationId,
}) => {
  const { updateStudyHoursPeriod, error, loading } = useUpdateStudyHoursPeriod()

  const validationSchema = Yup.object().shape({
    period: Yup.string().required("Period is required a"),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      period: currentPeriod,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const updatedHours = await updateStudyHoursPeriod(
        Number(organizationId),
        values.period,
        handleCreateSuccess
      )
      if (updatedHours) {
        formik.resetForm()
        onSuccess && onSuccess()
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(
      `Successfully updated the default study hours period to ${formik.values.period}!`
    )
    close()
  }

  return (
    <Dialog
      title={"Update Study Hours Period"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            sx={{ mt: 1 }}
          >
            <InputLabel id="period">Period</InputLabel>
            <Select
              labelId="period"
              value={formik.values.period}
              onChange={formik.handleChange}
              name={"period"}
              label="Period"
            >
              <MenuItem
                value="weekly"
                selected={formik.values.period === "weekly"}
              >
                Weekly
              </MenuItem>
              <MenuItem
                value="biweekly"
                selected={formik.values.period === "biweekly"}
              >
                Biweekly
              </MenuItem>
              <MenuItem
                value="monthly"
                selected={formik.values.period === "monthly"}
              >
                Monthly
              </MenuItem>
            </Select>
          </FormControl>
          <Typography>
            <b>Weekly:</b> Resets at 11:59pm every Sunday
          </Typography>
          <Typography>
            <b>Biweekly:</b> Resets at 11:59pm every other Sunday
          </Typography>
          <Typography>
            <b>Monthly:</b> Resets at 11:59pm on the last day of the month
          </Typography>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Save Changes
          </Button>
        </>
      }
    />
  )
}

export default EditPeriodDialog
