import React, { FunctionComponent } from "react"
import { MemberStudySession } from "../../../types"
import { Button } from "@mui/material"
import useDeleteStudySession from "../hooks/useDeleteStudySession"
import toast from "react-hot-toast"
import { logEvent } from "../../../utils/analytics-service"

interface Props {
  studySession: MemberStudySession
  organizationId: number
  memberId: number
}

const StudyHistoryItem: FunctionComponent<Props> = ({
  organizationId,
  memberId,
  studySession,
}) => {
  const [hidden, setHidden] = React.useState(false)
  const { deleteStudySession, error: studyError } = useDeleteStudySession()

  const handleDeleteSession = async () => {
    logEvent("delete_study_session_clicked", { id: studySession.id })
    // Show alert to confirm deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this session?"
    )
    if (!confirmDelete) {
      return
    }

    await deleteStudySession(organizationId, memberId, studySession.id, () =>
      setHidden(true)
    )
  }

  React.useEffect(() => {
    if (studyError) {
      toast.error(studyError.message)
    }
  }, [studyError])

  if (hidden) {
    return null
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "10px",
        margin: "10px",
        boxShadow: "0px 2px 4px black",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "5px",
          color: "black",
        }}
      >
        {studySession.location.name}
      </h4>
      <p
        style={{
          fontSize: "14px",
          color: "#333",
          marginBottom: "2px",
        }}
      >
        Start: {new Date(studySession.started_at).toLocaleString()}
      </p>
      <p
        style={{
          fontSize: "14px",
          color: "#333",
          marginBottom: "2px",
        }}
      >
        End:{" "}
        {studySession.ended_at
          ? new Date(studySession.ended_at).toLocaleString()
          : "Ongoing"}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "500",
          color: "#333",
          marginTop: "5px",
        }}
      >
        Duration: {studySession.duration_minutes} minutes
      </p>
      <Button
        style={{
          marginTop: "10px",
          alignSelf: "center",
          padding: "5px 20px",
        }}
        variant="contained"
        color="primary"
        onClick={handleDeleteSession}
      >
        Delete Session
      </Button>
    </div>
  )
}

export default StudyHistoryItem
