import React, { useCallback, useState, useRef, useEffect } from "react"
import {
  GoogleMap,
  useJsApiLoader,
  Circle,
  Marker,
} from "@react-google-maps/api"
import { StudyLocation } from "../../../types"
import config from "../../../config"
import StudyLocationDialog from "./StudyLocationDialog"
import { logEvent } from "../../../utils/analytics-service"

const containerStyle = {
  width: "90%",
  height: "600px",
}

const StudyLocationsMap = ({
  studyLocations,
  onSuccess,
  organizationId,
}: {
  studyLocations: Array<StudyLocation>
  onSuccess: () => void
  organizationId: number
}) => {
  const [updateStudyLocationOpen, setUpdateStudyLocationOpen] = useState(false)
  const [selectedStudyLocation, setSelectedStudyLocation] =
    useState<StudyLocation | null>(null)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.mapsAPIKey,
  })

  const center = {
    lat: studyLocations[0]?.latitude,
    lng: studyLocations[0]?.longitude,
  }

  const mapRef = useRef<google.maps.Map | null>(null)

  const onLoad = useCallback((map: any) => {
    mapRef.current = map
    const bounds = new window.google.maps.LatLngBounds()
    studyLocations.forEach((location) => {
      bounds.extend(
        new window.google.maps.LatLng(location.latitude, location.longitude)
      )
    })
    map.fitBounds(bounds)
  }, [])

  const onUnmount = useCallback(() => {
    mapRef.current = null
  }, [])

  const handleMarkerClick = (location: StudyLocation) => {
    setSelectedStudyLocation(location)
    setUpdateStudyLocationOpen(true)
  }

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds()
      studyLocations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.latitude, location.longitude)
        )
      })
      mapRef.current.fitBounds(bounds)
    }
  }, [isLoaded, studyLocations])

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {studyLocations.map((location) => (
          <React.Fragment key={location.id}>
            <Circle
              center={{
                lat: location.latitude,
                lng: location.longitude,
              }}
              radius={location.radius}
              options={{
                strokeColor: "#FF4444",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF4444",
                fillOpacity: 0.35,
              }}
            />
            <Marker
              position={{
                lat: location.latitude,
                lng: location.longitude,
              }}
              label={{
                text: location.name,
                color: "black",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              onClick={() => {
                logEvent("edit_study_location_button_clicked", {
                  id: location.id,
                })
                handleMarkerClick(location)
              }}
            />
          </React.Fragment>
        ))}
        <></>
      </GoogleMap>
      <StudyLocationDialog
        isOpen={updateStudyLocationOpen}
        close={() => {
          setUpdateStudyLocationOpen(false)
          setSelectedStudyLocation(null)
        }}
        studyLocation={selectedStudyLocation}
        onSuccess={onSuccess}
        organizationId={organizationId}
      />
    </>
  ) : null
}

export default StudyLocationsMap
