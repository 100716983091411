import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Me } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateMe = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const update = useCallback(
    (body: object): Promise<Me> => {
      return makeRequest<Me>(`${config.apiUrl}/v1/users/me`, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...body }),
      })
    },
    [authBearer]
  )

  const updateMe = useCallback(
    async (body: object, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await update(body)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [update, setLoading, setError]
  )

  return { updateMe, loading, error }
}

export default useUpdateMe
