import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title1 } from "../../components/shared/Typography"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material"
import useGetMe from "./hooks/useGetMe"
import useUpdateProfilePhoto from "./hooks/useUploadProfilePhoto"
import AvatarEditor from "react-avatar-editor"
import useGetMeOrgMember from "./hooks/useGetMeOrgMember"
import useUpdateMe from "./hooks/useUpdateMe"
import toast from "react-hot-toast"
import { logEvent } from "../../utils/analytics-service"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/shared/shadcn/Dialog"
import { Label } from "../../components/shared/shadcn/Label"
import { Input } from "../../components/shared/shadcn/Input"

const AccountScreen: FunctionComponent = () => {
  const { getMe, loading, error, me } = useGetMe()
  const {
    getMeOrgMember,
    loading: meOrgLoading,
    error: meOrgError,
    meOrgMember,
  } = useGetMeOrgMember()
  const {
    updateProfilePhoto,
    loading: loadingPFP,
    error: errorPFP,
  } = useUpdateProfilePhoto()
  const {
    updateMe,
    loading: updateMeLoading,
    error: updateMeError,
  } = useUpdateMe()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [scale, setScale] = useState<number>(1)

  const [isBirthdayModalOpen, setIsBirthdayModalOpen] = useState(false)
  const [birthday, setBirthday] = useState({ month: "", day: "", year: "" })

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ]

  useEffect(() => {
    getMe()
    getMeOrgMember()
    // eslint-disable-next-line
  }, [])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      setSelectedFile(file)
    }
  }

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(event.target.value)
    setScale(newScale)
  }

  const handleUpload = async () => {
    if (selectedFile) {
      const editor = editorRef.current
      if (editor) {
        const canvas = editor.getImageScaledToCanvas()
        const croppedImageUrl = canvas.toDataURL()
        setCroppedImage(croppedImageUrl)
        try {
          await updateProfilePhoto(croppedImageUrl, () => getMe())
        } catch (error) {
          // Handle error during profile photo update
          console.error("Error updating profile photo:", error)
        }
      }
    }
  }

  const handleBirthdaySubmit = async () => {
    await updateMe(
      {
        birthday: {
          month: birthday.month,
          day: birthday.day,
          year: birthday.year,
        },
      },
      () => {
        setIsBirthdayModalOpen(false)
        getMe()
      }
    )
  }

  if (updateMeError) {
    toast.error(updateMeError.message)
  }

  const editorRef = React.createRef<AvatarEditor>()

  if (loading || meOrgLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!me) {
    return <Title1>Not found</Title1>
  }

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      {meOrgError ? <ErrorComponent error={meOrgError} /> : null}
      {loading || meOrgLoading ? <LoadingSpinner /> : null}
      <Dialog>
        <Card sx={{ minWidth: 275, margin: 3 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Avatar
                  alt={me.name}
                  src={`${me.profile_photo}`}
                  sx={{ width: 100, height: 100 }}
                />
                {errorPFP ? <ErrorComponent error={errorPFP} /> : null}
                <Typography variant="h4">{me.name}</Typography>
                <p>{me.email}</p>
                {meOrgMember && (
                  <p>
                    {meOrgMember.points} Point
                    {meOrgMember.points === 1 ? "" : "s"}
                  </p>
                )}
                {me.birthday && <p>Birthday: {me.birthday}</p>}
              </Grid>
            </Grid>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="profile-photo-input"
            />
            {/* <DialogTrigger asChild>
              <Button variant="contained">Edit Profile</Button>
            </DialogTrigger> */}
            <label htmlFor="profile-photo-input">
              <Button variant="contained" component="span">
                Upload Profile Photo
              </Button>
            </label>
            {!me.birthday && (
              <Button
                variant="contained"
                component="span"
                sx={{ ml: 2 }}
                onClick={() => {
                  logEvent("settings_add_birthday_pressed")
                  setIsBirthdayModalOpen(true)
                }}
              >
                Set Birthday
              </Button>
            )}
            {selectedFile && (
              <div>
                <AvatarEditor
                  ref={editorRef}
                  image={selectedFile}
                  width={200}
                  height={200}
                  border={50}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={scale}
                />
                <div>
                  <label htmlFor="scale">Zoom:</label>
                  <input
                    id="scale"
                    type="range"
                    value={scale}
                    onChange={handleScaleChange}
                    min="1"
                    max="2"
                    step="0.01"
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={handleUpload}
                  disabled={loadingPFP}
                >
                  Upload Image
                </Button>
              </div>
            )}
          </CardContent>
          <Modal
            open={isBirthdayModalOpen}
            onClose={() => setIsBirthdayModalOpen(false)}
            aria-labelledby="update-birthday-modal"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" mb={2}>
                Enter Your Birthday
              </Typography>
              <FormControl fullWidth margin="dense">
                <InputLabel id="month-select-label">Month</InputLabel>
                <Select
                  labelId="month-select-label"
                  value={birthday.month}
                  onChange={(e) =>
                    setBirthday({ ...birthday, month: e.target.value })
                  }
                  label="Month"
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Day"
                fullWidth
                value={birthday.day}
                onChange={(e) =>
                  setBirthday({ ...birthday, day: e.target.value })
                }
                margin="dense"
                type="number"
              />
              <TextField
                label="Year"
                fullWidth
                value={birthday.year}
                onChange={(e) =>
                  setBirthday({ ...birthday, year: e.target.value })
                }
                margin="dense"
                type="number"
              />
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleBirthdaySubmit}
              >
                Submit
              </Button>
            </Box>
          </Modal>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Edit profile</DialogTitle>
              <DialogDescription>
                Make changes to your profile here. Click save when you're done.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Name
                </Label>
                <Input id="name" value="Pedro Duarte" className="col-span-3" />
              </div>
            </div>
            <DialogFooter>
              <Button type="submit">Save changes</Button>
            </DialogFooter>
          </DialogContent>
        </Card>
      </Dialog>
    </ScreenContainer>
  )
}

export default AccountScreen
