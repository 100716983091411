import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import AvatarEditor from "react-avatar-editor"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import useUpdateHouse from "./hooks/useUpdateHouse"
import { useAuth } from "../../services/auth-service"
import toast from "react-hot-toast"
import { scopes } from "../../scopes"
import {
  School,
  SchoolOrganization,
  Term,
  UpdateOrganization,
} from "../../types"
import DeleteOrganizationDialog from "./components/DeleteOrganizationDialog"
import useGetSchools from "../Schools/hooks/useGetSchools"
import useGetSchoolOrganizations from "../Schools/hooks/useGetSchoolOrganizations"
import useGetBillingDetails from "./hooks/useGetBillingDetails"
import useUpdateOrganizationSubscription from "./hooks/useUpdateOrganizationSubscription"
import useGetOrganizationTerms from "./hooks/useGetOrganizationTerms"
import styles from "./OrganizationSettingsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import { lightTheme } from "../../constants/theme"
import useGetTimezones from "./hooks/useGetTimezones"
import { logEvent } from "../../utils/analytics-service"
import { useParams } from "react-router-dom"

interface Params {
  organizationId?: string
}

const OrganizationSettingsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const {
    getHouseById,
    house: organization,
    loading: houseLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    updateHouse,
    loading: updateLoading,
    error: updateError,
  } = useUpdateHouse()
  const {
    getBillingDetails,
    billingDetails,
    loading: billingLoading,
    error: billingError,
  } = useGetBillingDetails()
  const {
    updateOrganizationSubscription,
    loading: updateSubscriptionLoading,
    error: updateSubscriptionError,
  } = useUpdateOrganizationSubscription()

  // Schools
  const { getSchools, schools } = useGetSchools()
  const { getSchoolOrganizations, schoolOrganizations } =
    useGetSchoolOrganizations()

  // Terms
  const { getOrganizationTerms, terms } = useGetOrganizationTerms()

  // Timezones
  const { getTimezones, timezones } = useGetTimezones()

  const [editAvatarIsOpen, setEditAvatarIsOpen] = useState<boolean>(false)

  const { hasScope } = useAuth()

  const editorRef = React.createRef<AvatarEditor>()

  const [organizationName, setOrganizationName] = useState<string>("")
  const [selectedSchool, setSelectedSchool] = useState<School>()
  const [selectedSchoolOrganization, setSelectedSchoolOrganization] =
    useState<SchoolOrganization>()
  const [selectedTermID, setSelectedTermID] = useState<number>()
  const [selectedTimezone, setSelectedTimezone] = useState<string>("")
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [scale, setScale] = useState<number>(1)
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)

  const canManageBilling = hasScope(scopes.organization.billing.manage)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organization = await getHouseById(Number(organizationId))
        await getOrganizationTerms(Number(organizationId))
        if (canManageBilling) {
          await getBillingDetails(Number(organizationId))
        }

        if (organization) {
          localStorage.setItem(
            "activeOrganization",
            JSON.stringify(organization)
          )
          setOrganizationName(organization.name)
          if (organization.school) {
            setSelectedSchool(organization.school)
          }
          if (organization.school_organization) {
            setSelectedSchoolOrganization(organization.school_organization)
          }
          if (organization.timezone) {
            setSelectedTimezone(organization.timezone)
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
    getTimezones()
    getSchools()
    getSchoolOrganizations()
  }, [])

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]

    if (file) {
      setSelectedFile(file)

      // Read the selected file as data URL
      const reader = new FileReader()

      reader.onload = async (e) => {
        const imageDataUrl = e.target?.result as string

        // Set the cropped image directly without relying on scale
        setCroppedImage(imageDataUrl)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleEditAvatar = () => {
    const body = {
      name: organizationName,
      photo: croppedImage,
      schoolId: selectedSchool?.id,
      schoolOrganizationId: selectedSchoolOrganization?.id,
      termId: selectedTermID,
    }
    updateHouse(organization!.id, body, updateOrganizationSuccess)
    setEditAvatarIsOpen(false)
  }

  useEffect(() => {
    if (selectedFile) {
      setEditAvatarIsOpen(true)
    }
  }, [selectedFile])

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(event.target.value)
    setScale(newScale)
    if (selectedFile) {
      const editor = editorRef.current
      if (editor) {
        const canvas = editor.getImageScaledToCanvas()
        const croppedImageUrl = canvas.toDataURL()
        setCroppedImage(croppedImageUrl)
      }
    }
  }

  const handleSaveChanges = async () => {
    let updateBody: UpdateOrganization = {
      name: organizationName,
    }

    if (croppedImage) {
      updateBody.photo = croppedImage
    }

    if (selectedSchool && organization?.school === null) {
      updateBody.schoolId = selectedSchool.id
    }

    if (
      selectedSchoolOrganization &&
      organization?.school_organization === null
    ) {
      updateBody.schoolOrganizationId = selectedSchoolOrganization.id
    }

    if (selectedTermID) {
      updateBody.termId = selectedTermID
    }

    if (selectedTimezone) {
      updateBody.timezone = selectedTimezone
    }

    if (organization) {
      await updateHouse(organization.id, updateBody, updateOrganizationSuccess)
    }
  }

  const updateOrganizationSuccess = async () => {
    toast.success("Successfully updated organization")
    const organizationId = localStorage.getItem("activeOrganizationId")
    if (organizationId) {
      await getHouseById(Number(organizationId))
    }
  }

  const updateSubscriptionSuccess = async () => {
    toast.success(
      "Successfully updated subscription. Changes may take a few minutes to reflect."
    )
    const organizationId = localStorage.getItem("activeOrganizationId")
    if (organizationId) {
      await getHouseById(Number(organizationId))
    }
  }

  const canEditOrganization = hasScope(scopes.organization.update)

  let hasPromoApplied = false
  let promoText = ""

  if (
    billingDetails &&
    billingDetails.subscription?.plan?.discount &&
    billingDetails.subscription?.plan?.discount?.name !== null
  ) {
    hasPromoApplied = true
    if (billingDetails.subscription.plan.discount.amount_off !== null) {
      promoText = `$${(
        billingDetails.subscription.plan.discount.amount_off / 100
      ).toFixed(2)} off`
    } else if (billingDetails.subscription.plan.discount.percent_off !== null) {
      promoText = `${billingDetails.subscription.plan.discount.percent_off}% off`
    } else {
      promoText = "Promo applied"
    }
  }

  const hasPaymentMethods =
    (billingDetails?.payment_methods?.length ?? 0) > 0 || false

  const handleClose = () => {
    setEditAvatarIsOpen(false)
    setSelectedFile(null)
    setCroppedImage(null)
  }

  return (
    <ScreenContainer requiresScope={scopes.organization.read}>
      <Box
        display="flex"
        width="100%"
        className={styles.organizationSettingsScreen}
        flexDirection="column"
        mb="24px"
      >
        {updateLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          {organization ? organization.name : ""} Settings
        </Typography>
        {updateError && <ErrorComponent error={updateError} />}
        <Box className={styles.topContainer}>
          <Box className={styles.avatarContainer}>
            <Avatar
              alt={organization?.name}
              src={`${organization?.photo}`}
              sx={{ borderRadius: "50%", height: "100%", width: "100%" }}
            />
            {canEditOrganization && (
              <>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="profile-photo-input"
                />
                <label htmlFor="profile-photo-input">
                  <FontAwesomeIcon
                    className={styles.editProfilePic}
                    icon={faEdit}
                  />
                </label>
              </>
            )}
            {selectedFile && (
              <Dialog
                open={editAvatarIsOpen}
                onClose={() => handleClose()}
                fullWidth
                className={styles.editAvatarDialog}
                maxWidth="sm"
              >
                <AvatarEditor
                  ref={editorRef}
                  image={selectedFile}
                  width={200}
                  height={200}
                  border={50}
                  color={[255, 255, 255, 0.6]}
                  backgroundColor="#f0f0f0"
                  scale={scale}
                />
                <div className={styles.zoomContainer}>
                  <label htmlFor="scale">Zoom:</label>
                  <input
                    id="scale"
                    type="range"
                    value={scale}
                    onChange={handleScaleChange}
                    min="1"
                    max="2"
                    step="0.01"
                  />
                </div>
                <Button
                  onClick={handleEditAvatar}
                  sx={{
                    backgroundColor: lightTheme.palette.primary.main,
                    color: lightTheme.palette.background.default,
                    transition: "all 0.3s",
                    width: "80%",
                    margin: "auto",
                    marginBottom: "20px",
                    marginTop: "20px",
                    "&:hover": {
                      backgroundColor: lightTheme.palette.primary.main,
                      color: lightTheme.palette.background.default,
                      opacity: 0.8,
                    },
                  }}
                >
                  Save
                </Button>
              </Dialog>
            )}
          </Box>
          <Box className={styles.editOrgContainer}>
            <TextField
              label="Organization Name"
              variant="outlined"
              required
              fullWidth
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}
              disabled={!canEditOrganization}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  key={
                    selectedSchool
                      ? `school-${selectedSchool.id}`
                      : "emptySchool"
                  }
                  options={schools}
                  value={selectedSchool}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    setSelectedSchool(newValue as School)
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="School" variant="outlined" />
                  )}
                  sx={{ width: "100%" }}
                  disabled={
                    !canEditOrganization || organization?.school !== null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  key={
                    selectedSchoolOrganization
                      ? `schoolOrganization-${selectedSchoolOrganization.id}`
                      : "emptySchoolOrganization"
                  }
                  options={schoolOrganizations}
                  value={selectedSchoolOrganization}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    setSelectedSchoolOrganization(
                      newValue as SchoolOrganization
                    )
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization"
                      variant="outlined"
                    />
                  )}
                  sx={{ width: "100%" }}
                  disabled={
                    !canEditOrganization ||
                    organization?.school_organization !== null
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  key={terms ? `term-${organization?.term?.id}` : "emptyTerm"}
                  options={terms || []}
                  value={terms?.find(
                    (term) => term.id === organization?.term?.id
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    setSelectedTermID((newValue as Term).id)
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Current Term"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, readOnly: true }} // Make the input field read-only
                    />
                  )}
                  sx={{ width: "100%" }}
                  disabled={!canEditOrganization}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  key={
                    timezones
                      ? `timezone-${organization?.timezone}`
                      : "emptyTimezone"
                  }
                  options={timezones || []}
                  value={organization?.timezone}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event, newValue) => {
                    setSelectedTimezone(newValue as string)
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Timezone"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, readOnly: true }} // Make the input field read-only
                    />
                  )}
                  sx={{ width: "100%" }}
                  disabled={!canEditOrganization}
                />
              </Grid>
            </Grid>
            <br />
            {canEditOrganization && (
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={handleSaveChanges}
                disabled={houseLoading || updateLoading}
              >
                Save changes
              </Button>
            )}
          </Box>
        </Box>
        {canManageBilling && billingDetails && (
          <Box className={styles.subscriptionContainer}>
            <Box className={styles.currentAndBalanceContainer}>
              <Box className={styles.activeSubscriptionContainer}>
                <Box>
                  <Typography
                    marginBottom={"10px"}
                    variant="h5"
                    sx={{ fontWeight: 400, mt: 2 }}
                  >
                    <b>Current Subscription</b>
                  </Typography>
                </Box>
                <Divider style={{ width: "90%", marginBottom: "10px" }} />
                <Box>
                  {billingDetails.subscription ? (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={billingDetails.subscription.id}
                    >
                      <Typography align="center" component="div" gutterBottom>
                        Base Subscription{" "}
                        {hasPromoApplied && (
                          <Chip color="primary" label={promoText} />
                        )}
                      </Typography>
                      {billingDetails.subscription.is_trial && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          You are currently in free trial until{" "}
                          {new Date(
                            billingDetails.subscription.plan.trial_end * 1000
                          ).toLocaleDateString()}
                        </Typography>
                      )}
                      <Typography variant="body2" component="div">
                        Current Billing Period:{" "}
                        {new Date(
                          billingDetails.subscription.plan
                            .current_period_start * 1000
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(
                          billingDetails.subscription.plan.current_period_end *
                            1000
                        ).toLocaleDateString()}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={"no-active-base-subscription"}
                    >
                      <Typography align="center" component="div" gutterBottom>
                        No active subscription
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Choose a plan to reactivate your subscription
                      </Typography>
                    </Grid>
                  )}
                  {billingDetails.payment_methods.map((paymentMethod) => (
                    <Box
                      border={`solid 1px ${lightTheme.palette.text.primary}`}
                      className={styles.paymentDetailsContainer}
                      key={paymentMethod.id}
                    >
                      {paymentMethod.card && (
                        <>
                          {paymentMethod.card.icon_url && (
                            <Avatar
                              alt={paymentMethod.card.brand}
                              src={paymentMethod.card.icon_url}
                              sx={{ width: 50, height: 50, borderRadius: 0 }}
                            />
                          )}
                          <Typography
                            variant="h6"
                            component="div"
                            marginLeft={"10px"}
                          >
                            {` •••• ${paymentMethod.card.last4}`}
                          </Typography>
                        </>
                      )}
                      {paymentMethod.us_bank_account && (
                        <>
                          <Typography variant="h5" component="div" gutterBottom>
                            {`${paymentMethod.us_bank_account.name} •••• ${paymentMethod.us_bank_account.last4}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            gutterBottom
                          >
                            {paymentMethod.card
                              ? `${paymentMethod.card.last4}`
                              : null}
                          </Typography>
                        </>
                      )}
                      {/* <Box sx={{ mt: 2, width: "100%" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ textTransform: "none" }}
                          disabled={billingLoading || updateSubscriptionLoading}
                          fullWidth
                        >
                          Remove Payment Method
                        </Button>
                      </Box> */}
                    </Box>
                  ))}
                </Box>
                <Box>
                  {billingDetails.payment_methods.length > 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "none" }}
                      disabled={billingLoading || updateSubscriptionLoading}
                      fullWidth
                      onClick={() => {
                        logEvent("settings_add_payment_method_button_clicked")
                        window.location.href =
                          billingDetails.add_payment_method_url
                      }}
                    >
                      Add New Payment Method
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "none", mt: 2, mb: 4 }}
                      onClick={() => {
                        logEvent("settings_add_payment_method_button_clicked")
                        window.location.href =
                          billingDetails.add_payment_method_url
                      }}
                    >
                      Add Payment Method
                    </Button>
                  )}
                </Box>
              </Box>
              {billingDetails.credit_balance !== 0 && (
                <Box className={styles.accountBalanceContainer}>
                  <Grid item xs={6} sm={3} md={3} key={"account-balance"}>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                    >
                      Account Balance
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      align="center"
                      gutterBottom
                    >
                      <b>{`$${(
                        Math.abs(billingDetails.credit_balance) / 100
                      ).toFixed(2)}`}</b>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                      gutterBottom
                    >
                      Your account balance will be applied to your next invoice
                      or subscription renewal.
                    </Typography>
                  </Grid>
                </Box>
              )}
              <Box className={styles.accountBalanceContainer}>
                <Grid item xs={6} sm={3} md={3} key={"account-balance"}>
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Asset Storage
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    align="center"
                    gutterBottom
                  >
                    <b>
                      {billingDetails.asset_storage.formatted_used} out of{" "}
                      {billingDetails.asset_storage.formatted_limit} used (
                      {(
                        (billingDetails.asset_storage.used /
                          billingDetails.asset_storage.limit) *
                        100
                      ).toFixed(2)}
                      %)
                    </b>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    gutterBottom
                  >
                    {billingDetails.payment_methods.length > 0
                      ? "Contact customer support to increase your asset storage limit"
                      : "Add a payment method to increase your storage limit"}
                  </Typography>
                </Grid>
              </Box>
              {updateSubscriptionError && (
                <ErrorComponent error={updateSubscriptionError} />
              )}
            </Box>
            <Box className={styles.currentAndBalanceContainer}>
              <Box className={styles.accountBalanceContainer}>
                <Grid item xs={6} sm={3} md={3} key={"plans"}>
                  <Box marginBottom="20px">
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: 400, mt: 2, mb: 2 }}
                    >
                      <b>
                        {billingDetails.subscription ? "Plans" : "Subscribe"}
                      </b>
                    </Typography>
                    <Divider />
                    <Typography align="center" sx={{ mt: 2, width: "100%" }}>
                      {billingDetails.payment_methods.length === 0
                        ? "Add a payment method to subscribe!"
                        : "Choose a plan to get unlimited access!"}
                    </Typography>
                  </Box>
                  {billingDetails.plans.map((plan) => (
                    <Box className={styles.singlePlanContainer}>
                      <Typography component="div" gutterBottom>
                        <b>{plan.title}</b> -
                        {` $${(plan.price / 100).toFixed(2)}/${
                          plan.formatted_duration
                        }`}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: "none" }}
                        className={styles.subscribeButton}
                        disabled={
                          plan.currently_subscribed ||
                          billingLoading ||
                          updateSubscriptionLoading ||
                          !hasPaymentMethods
                        }
                        fullWidth
                        onClick={() => {
                          logEvent("settings_subscribe_button_clicked", {
                            plan: plan.id,
                          })
                          updateOrganizationSubscription(
                            organization!.id,
                            plan.id,
                            updateSubscriptionSuccess
                          )
                        }}
                      >
                        {billingDetails.subscription === null
                          ? "Subscribe"
                          : plan.currently_subscribed
                          ? "Current"
                          : "Switch"}
                      </Button>
                    </Box>
                  ))}
                </Grid>
              </Box>
              <Box className={styles.accountBalanceContainer}>
                <Grid item xs={6} sm={3} md={3} key={"billing-details"}>
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Billing Details
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    align="center"
                    gutterBottom
                  >
                    <b>{billingDetails.email}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    gutterBottom
                  >
                    This is where your receipts are sent. Contact customer
                    support to update your billing email.
                  </Typography>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
        {/* {canDeleteHouse && (
          <Button
            variant="contained"
            onClick={() => setDeleteOpen(true)}
            disabled={houseLoading || updateLoading}
            color="error"
          >
            ⚠️ DELETE ORGANIZATION
          </Button>
        )} */}
      </Box>
      {organization && (
        <DeleteOrganizationDialog
          isOpen={deleteOpen}
          close={() => setDeleteOpen(false)}
          organization={organization}
        />
      )}
    </ScreenContainer>
  )
}

export default OrganizationSettingsScreen
