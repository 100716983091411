import { faGridHorizontal } from "@fortawesome/pro-regular-svg-icons/faGridHorizontal"
import { faBullhorn } from "@fortawesome/pro-regular-svg-icons/faBullhorn"
import { faListTree } from "@fortawesome/pro-regular-svg-icons/faListTree"
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock"
import { faLocationCrosshairs } from "@fortawesome/pro-regular-svg-icons/faLocationCrosshairs"
import { faPeopleRoof } from "@fortawesome/pro-regular-svg-icons/faPeopleRoof"
import { faTally } from "@fortawesome/pro-regular-svg-icons/faTally"
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear"
import { faHouseUser } from "@fortawesome/pro-regular-svg-icons/faHouseUser"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown"
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus"
import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons/faRightToBracket"
import { faSquarePollVertical } from "@fortawesome/pro-regular-svg-icons/faSquarePollVertical"
import { faClipboardUser } from "@fortawesome/pro-regular-svg-icons"
import { faEye } from "@fortawesome/pro-regular-svg-icons"
import { faFaceSmilePlus } from "@fortawesome/pro-regular-svg-icons"

const icons = {
  grid: faGridHorizontal,
  bullhorn: faBullhorn,
  listTree: faListTree,
  clock: faClock,
  location: faLocationCrosshairs,
  peopleRoof: faPeopleRoof,
  tally: faTally,
  gear: faGear,
  houseUser: faHouseUser,
  down: faChevronDown,
  plus: faPlus,
  join: faRightToBracket,
  poll: faSquarePollVertical,
  clipBoardUser: faClipboardUser,
  gallery: faGridHorizontal,
  eye: faEye,
  addEmoji: faFaceSmilePlus,
}

export default icons
