import React from "react"
import { TableRow, TableCell, Box, Avatar } from "@mui/material"
import { AnnouncementView } from "../../../types"

interface Props {
  announcementView: AnnouncementView
}

const AnnouncementViewRow: React.FC<Props> = ({ announcementView }) => {
  return (
    <TableRow key={announcementView.id}>
      <TableCell sx={{ width: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt={announcementView.user.name}
            src={`${announcementView.user.profile_photo}`}
            sx={{ marginRight: 2 }}
          />
          {announcementView.user.name}
        </Box>
      </TableCell>
      <TableCell>
        {announcementView.views} view{announcementView.views === 1 ? "" : "s"}
      </TableCell>
    </TableRow>
  )
}

export default AnnouncementViewRow
