import { Button, Typography, CircularProgress, Box } from "@mui/material"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { MemberStudySession, OrganizationMember } from "../../../types"
import useGetStudySessionsForMember from "../hooks/useGetStudySessionsForMember"
import StudyHistoryItem from "./StudyHistoryItem"

interface Props {
  isOpen: boolean
  close: () => void
  member: OrganizationMember
  organizationId: number
}

const MemberStudySessionHistoryDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  member,
  organizationId,
}) => {
  const {
    getStudySessionsForMember,
    studySessions: fetchedSessions,
    loading,
    error,
    paginationData,
  } = useGetStudySessionsForMember()

  const [studySessions, setStudySessions] = useState<MemberStudySession[]>([]) // Store all loaded sessions
  const observerRef = useRef<HTMLDivElement | null>(null)

  // Fetch data when the dialog is opened
  useEffect(() => {
    if (isOpen) {
      getStudySessionsForMember(Number(organizationId), member.id)
    }
  }, [isOpen, member.id, getStudySessionsForMember, paginationData.page])

  // Append new sessions to the list
  useEffect(() => {
    if (fetchedSessions && fetchedSessions.length > 0) {
      setStudySessions((prevSessions) => [...prevSessions, ...fetchedSessions])
    }
  }, [fetchedSessions])

  const handleClose = () => {
    close()
    setStudySessions([]) // Clear sessions on close
    paginationData.onPageChange(null, 0) // Reset to the first page
  }

  // Infinite scrolling with IntersectionObserver
  useEffect(() => {
    if (loading || paginationData.count <= studySessions.length) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          paginationData.onPageChange(null, paginationData.page + 1) // Trigger page change
        }
      },
      { threshold: 1.0 }
    )

    if (observerRef.current) observer.observe(observerRef.current)

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current)
    }
  }, [loading, paginationData, studySessions.length])

  return (
    <Dialog
      title={`${member.user.name} Study Session History`}
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {!loading && studySessions.length === 0 && (
            <Typography variant="body1">No study sessions found</Typography>
          )}
          {studySessions.map((studySession) => (
            <StudyHistoryItem
              key={studySession.id}
              studySession={studySession}
              organizationId={organizationId}
              memberId={member.id}
            />
          ))}
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          <div ref={observerRef} />
        </>
      }
      buttonContent={
        <Button onClick={handleClose} color="error" variant="outlined">
          Close
        </Button>
      }
    />
  )
}

export default MemberStudySessionHistoryDialog
