import { Box, Button, CircularProgress, Typography } from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetStudyHours from "./hooks/useGetStudyHours"
import StudyHoursTable from "./components/StudyHoursTable"
import { scopes } from "../../scopes"
import useGetDefaultStudyHours from "./hooks/useGetDefaultStudyHours"
import EditDefaultStudyHoursDialog from "./components/EditDefaultStudyHoursDialog"
import styles from "./StudyHoursScreen.module.css"
import { lightTheme } from "../../constants/theme"
import EditPeriodDialog from "./components/EditPeriodDialog"
import { useParams } from "react-router-dom"

interface Params {
  organizationId?: string
}

const StudyHoursScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const [editDefaultHoursOpen, setEditDefaultHoursOpen] = useState(false)
  const [editPeriodOpen, setEditPeriodOpen] = useState(false)

  const {
    getStudyHours,
    paginationData,
    studyHours,
    loading: studyHoursLoading,
    error: studyHoursError,
  } = useGetStudyHours()

  const { getDefaultStudyHours, defaultHours } = useGetDefaultStudyHours()
  const [activeFilter, setActiveFilter] = useState("all")

  useEffect(() => {
    const fetchData = async () => {
      await getStudyHours(Number(organizationId))
      await getDefaultStudyHours(Number(organizationId))
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (activeFilter === "all") {
      getStudyHours(Number(organizationId))
    } else if (activeFilter === "complete") {
      getStudyHours(Number(organizationId), true)
    } else if (activeFilter === "incomplete") {
      getStudyHours(Number(organizationId), false)
    }
  }, [activeFilter])

  const activeFilterStyle = {
    color: lightTheme.palette.text.primary,
    fontWeight: "bold",
    "&::after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "2px",
      backgroundColor: lightTheme.palette.primary.main,
      marginTop: "2px",
    },
  }

  const inactiveFilterStyle = {
    color: lightTheme.palette.text.primary,
    opacity: 0.8,
  }

  if (studyHoursLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      requiresScope={scopes.organization.members.studyHours.read}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.studyHoursScreen}
      >
        {studyHoursError && <ErrorComponent error={studyHoursError} />}
        {studyHoursLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          Study Hours
        </Typography>
        <Box className={styles.tableFilterMenu}>
          <button
            className={styles.filterButton}
            onClick={() => setActiveFilter("all")}
          >
            <Typography
              variant="body1"
              sx={
                activeFilter === "all" ? activeFilterStyle : inactiveFilterStyle
              }
            >
              All
            </Typography>
          </button>
          <button
            className={styles.filterButton}
            onClick={() => setActiveFilter("complete")}
          >
            <Typography
              variant="body1"
              sx={
                activeFilter === "complete"
                  ? activeFilterStyle
                  : inactiveFilterStyle
              }
            >
              Complete
            </Typography>
          </button>
          <button
            className={styles.filterButton}
            onClick={() => setActiveFilter("incomplete")}
          >
            <Typography
              variant="body1"
              sx={
                activeFilter === "incomplete"
                  ? activeFilterStyle
                  : inactiveFilterStyle
              }
            >
              Incomplete
            </Typography>
          </button>
        </Box>
        <Box className={styles.defaultChangeContainer}>
          <Box className={styles.defaultChange}>
            <Typography variant="body1">
              <b>Default Required Hours:</b>{" "}
              {defaultHours?.default_study_hours || 0}
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginLeft: 2,
                padding: 0.5,
              }}
              onClick={() => setEditDefaultHoursOpen(true)}
            >
              Change
            </Button>
          </Box>
        </Box>
        <p>
          <b>Current period:</b> {defaultHours?.period_start || ""} -{" "}
          {defaultHours?.period_end || ""} ({defaultHours?.period}){" "}
          <Button
            variant="contained"
            sx={{
              marginLeft: 2,
              padding: 0.5,
            }}
            onClick={() => setEditPeriodOpen(true)}
          >
            Edit Period
          </Button>
        </p>
        <StudyHoursTable
          studyHours={studyHours}
          paginationData={paginationData}
          reloadStudyHours={() => getStudyHours(organizationId)}
          organizationId={organizationId}
        />
      </Box>
      <EditDefaultStudyHoursDialog
        isOpen={editDefaultHoursOpen}
        close={() => setEditDefaultHoursOpen(false)}
        onSuccess={() => getStudyHours(organizationId)}
        currentDefault={defaultHours?.default_study_hours || 0}
        organizationId={organizationId}
      />
      {editPeriodOpen && defaultHours?.period && (
        <EditPeriodDialog
          isOpen={editPeriodOpen}
          close={() => setEditPeriodOpen(false)}
          onSuccess={() => getDefaultStudyHours(organizationId)}
          currentPeriod={defaultHours.period}
          organizationId={organizationId}
        />
      )}
    </ScreenContainer>
  )
}

export default StudyHoursScreen
