import React, { createContext, useContext, useState, useEffect } from "react"
import config from "../config"
import { Me } from "../types"
import makeRequest from "../utils/make-request"
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth"
import { auth } from "../firebase.js"
import toast from "react-hot-toast"
import {
  loginAnalyticsUser,
  logoutAnalyticsUser,
} from "../utils/analytics-service"

type AuthContext = {
  getToken: () => string | null
  getUser: () => Me | null
  isAuthenticated: () => boolean
  handleLogin: (email: string, password: string) => Promise<void>
  handleSignup: (name: string, email: string, password: string) => Promise<void>
  handleLogout: () => void
  hasScope: (scope: string) => boolean
  handleForgotPassword: (email: string) => Promise<void>
}

const AuthContext = createContext(null as any as AuthContext)

const apiUrl = config.apiUrl

export const AuthProvider = ({ children }: { children: any }) => {
  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<Me | null>(null)

  const getToken = () => token
  const getUser = () => user
  const isAuthenticated = () => !!user
  const hasScope = (scope: string) =>
    !!user ? user.scopes.includes(scope) : false

  const handleLogout = async (): Promise<void> => {
    await signOut(auth)
    setToken(null)
    setUser(null)
    logoutAnalyticsUser()
    localStorage.removeItem("activeOrganizationId")
    localStorage.removeItem("activeOrganization")
  }

  const handleLogin = async (
    email: string,
    password: string
  ): Promise<void> => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user
        const idToken = await user.getIdToken()
        setToken(idToken)

        const userResponse = await getUserForToken(idToken)
        setUser(userResponse)

        // Setup analytics
        loginAnalyticsUser(userResponse)
      })
      .catch(async (error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode, errorMessage)
        await handleLogout()
        throw Error(errorMessage)
      })
  }

  const handleSignup = async (
    name: string,
    email: string,
    password: string
  ): Promise<void> => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user
        const idToken = await user.getIdToken()
        setToken(idToken)

        // Create user in Greek Chapter system
        const userId = userCredential.user.uid
        await createGreekChapterUser(name, email, userId)

        const userResponse = await getUserForToken(idToken)
        setUser(userResponse)

        // Setup analytics
        loginAnalyticsUser(userResponse)
      })
      .catch(async (error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode, errorMessage)
        await handleLogout()
        throw Error(errorMessage)
      })
  }

  const handleForgotPassword = async (email: string): Promise<void> => {
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent")
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode, errorMessage)
        throw Error(errorMessage)
      })
  }

  // Use useEffect to check for an authenticated user on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken()
        setToken(idToken)

        const userResponse = await getUserForToken(idToken)
        setUser(userResponse)
      } else {
        // No user is signed in.
        setToken(null)
        setUser(null)
      }
    })

    // Cleanup function
    return () => unsubscribe()
  }, [])

  const value = {
    getToken,
    getUser,
    isAuthenticated,
    handleLogin,
    handleSignup,
    handleLogout,
    hasScope,
    handleForgotPassword,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

const getUserForToken = async (token: string): Promise<Me> => {
  const headers: Record<string, string> = {
    authorization: `Bearer ${token}`,
  }

  const activeOrganizationId = localStorage.getItem("activeOrganizationId")

  if (activeOrganizationId) {
    headers["X-Organization-Id"] = activeOrganizationId
  }

  return makeRequest<Me>(`${apiUrl}/v1/users/me`, {
    method: "GET",
    headers,
  })
}

const createGreekChapterUser = async (
  name: string,
  email: string,
  identityId: string
): Promise<void> => {
  return makeRequest<void>(`${apiUrl}/v1/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, email, identityId }),
  })
}
