import { Button, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { OrganizationMember } from "../../../types"
import useUpdateRequiredStudyHoursForMember from "../hooks/useUpdateRequiredStudyHoursForMember"

interface Props {
  isOpen: boolean
  close: () => void
  member: OrganizationMember
  onSuccess?: () => void
  organizationId: number
}

const EditMemberRequiredStudyHoursDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  member,
  onSuccess,
  organizationId,
}) => {
  const { updateRequiredStudyHoursForMember, error, loading } =
    useUpdateRequiredStudyHoursForMember()

  const validationSchema = Yup.object().shape({
    hours: Yup.number()
      .integer()
      .required("Hours is required and must be a full number"),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      hours: member.required_study_hours,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const updatedHours = await updateRequiredStudyHoursForMember(
        Number(organizationId),
        member.id,
        values.hours,
        handleUpdateSuccess
      )
      if (updatedHours) {
        formik.resetForm()
        onSuccess && onSuccess()
      }
    },
  })

  const handleUpdateSuccess = () => {
    onSuccess && onSuccess()
  }

  return (
    <Dialog
      title={`Update required study hours for ${member.user.name}`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            margin="dense"
            id="hours"
            name="hours"
            label={"Required Study Hours"}
            fullWidth
            required
            variant="outlined"
            type="number"
            onChange={formik.handleChange}
            error={!!formik.errors.hours}
            value={formik.values.hours}
          />
          <Typography>
            This just updates the required study hours for this member. It does
            not affect the default study hours for the entire organization or
            other members.
          </Typography>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Save Changes
          </Button>
        </>
      }
    />
  )
}

export default EditMemberRequiredStudyHoursDialog
