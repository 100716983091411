import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Announcement } from "../../../types"

const useUpdateAnnouncementReaction = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Announcement>()

  const request = useCallback(
    (
      organizationId: number,
      announcementId: number,
      reaction: string | null
    ): Promise<Announcement> => {
      return makeRequest<Announcement>(
        `${config.apiUrl}/v1/organizations/${organizationId}/announcements/${announcementId}/reactions`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
          body: JSON.stringify({ reaction }),
        }
      )
    },
    [authBearer]
  )

  const updateAnnouncementReaction = useCallback(
    async (
      organizationId: number,
      announcementId: number,
      reaction: string | null,
      onSuccess?: Function
    ) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, announcementId, reaction)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    updateAnnouncementReaction,
    announcement: data,
  }
}

export default useUpdateAnnouncementReaction
