import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { Announcement } from "../../../types"
import AnnouncementRow from "./AnnouncementRow"

interface Props {
  announcements: Announcement[]
  paginationData: PaginationData
  refreshData: () => void
  organizationId: number
}

const getRows = (
  announcements: Announcement[],
  refreshData: () => void,
  organizationId: number
) => {
  return announcements.map((announcement, index) => {
    return (
      <AnnouncementRow
        key={index}
        announcement={announcement}
        refreshData={refreshData}
        organizationId={organizationId}
      />
    )
  })
}

const AnnouncementsTable = ({
  announcements,
  paginationData,
  refreshData,
  organizationId,
}: Props) => {
  const columns: string[] = ["Author", "Title", "Message", "Active", "Actions"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(announcements, refreshData, organizationId)}
      paginationOptions={paginationData}
    />
  )
}

export default AnnouncementsTable
