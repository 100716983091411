import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { MemberStudySession } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetStudySessionsForMember = (defaultRowsPerPage = 25) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<MemberStudySession>>()

  const [organizationId, setOrganizationId] = useState<number>()
  const [memberId, setMemberId] = useState<number>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (organizationId && memberId) {
      getStudySessionsForMember(organizationId, memberId)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback(
    (
      organizationId: number,
      memberId: number
    ): Promise<PagedResponse<MemberStudySession>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}`
      return makeRequest<PagedResponse<MemberStudySession>>(
        `${config.apiUrl}/v1/organizations/${organizationId}/members/${memberId}/study-hours/sessions?${queryParams}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getStudySessionsForMember = useCallback(
    async (organizationId: number, memberId: number) => {
      setOrganizationId(organizationId)
      setMemberId(memberId)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, memberId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getStudySessionsForMember,
    studySessions: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetStudySessionsForMember
