import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { AttendanceItem } from "../../../types"
import { useNavigate } from "react-router-dom"
import { lightTheme } from "../../../constants/theme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import icons from "../../../assets/Icons/icons"

type Props = {
  attendanceItem: AttendanceItem
  organizationId: number
}

const AttendanceItemRow: FunctionComponent<Props> = ({
  attendanceItem,
  organizationId,
}) => {
  const navigate = useNavigate()

  return (
    <BasicTableRow
      key={attendanceItem.id}
      onClick={() =>
        navigate(
          `/organizations/${organizationId}/attendance/${attendanceItem.id}`
        )
      }
      sx={{
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: lightTheme.palette.secondary.main,
          cursor: "pointer",
        },
      }}
    >
      <BasicTableCell component="th" scope="row">
        <FontAwesomeIcon icon={icons.clipBoardUser} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {attendanceItem.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {attendanceItem.created_at}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default AttendanceItemRow
