import { Box, Typography, Button, CircularProgress } from "@mui/material"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetGuestLists from "./hooks/useGetGuestLists"
import GuestListsTable from "./components/GuestListsTable"
import { scopes } from "../../scopes"
import GuestListDialog from "./components/GuestListDialog"
import styles from "./GuestListsScreen.module.css"
import { useParams } from "react-router-dom"

interface Params {
  organizationId?: string
}

const GuestListsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const [createGuestListOpen, setCreateGuestListOpen] = useState(false)

  const {
    getGuestLists,
    paginationData,
    guestLists,
    loading: guestListsLoading,
    error: guestListsError,
  } = useGetGuestLists()

  useEffect(() => {
    const fetchData = async () => {
      await getGuestLists(Number(organizationId))
    }

    fetchData()
  }, [])

  if (guestListsLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <>
      <ScreenContainer requiresScope={scopes.organization.guestLists.create}>
        <Box
          className={styles.createButtonContainer}
          onClick={() => setCreateGuestListOpen(true)}
        >
          <AddButton className="className" />
        </Box>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            mb: "24px",
          }}
          className={styles.guestListsScreen}
        >
          {guestListsError && <ErrorComponent error={guestListsError} />}
          {guestListsLoading && <CircularProgress />}
          {guestLists.length === 0 ? (
            <>
              <Typography variant="h5">
                <b>No guest lists found</b>
              </Typography>
              <Typography variant="body1">
                Manage event guest lists and set member guest limits
              </Typography>
              <Button
                onClick={() => setCreateGuestListOpen(true)}
                variant="contained"
                style={{ marginTop: "10px", cursor: "pointer" }}
              >
                Create Guest List
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h3" className={styles.title}>
                Guest Lists
              </Typography>
              <GuestListsTable
                guestLists={guestLists}
                paginationData={paginationData}
                organizationId={organizationId}
              />
            </>
          )}
        </Box>
        <GuestListDialog
          isOpen={createGuestListOpen}
          close={() => setCreateGuestListOpen(false)}
          onSuccess={() => getGuestLists(organizationId)}
          organizationId={organizationId}
        />
      </ScreenContainer>
    </>
  )
}

export default GuestListsScreen
