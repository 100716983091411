import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Tooltip,
} from "@mui/material"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { useParams } from "react-router-dom"
import styles from "../GuestLists/GuestListDetailsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import useGetAnnouncementById from "./hooks/useGetAnnouncementById"
import icons from "../../assets/Icons/icons"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { logEvent } from "../../utils/analytics-service"
import useUpdateAnnouncementReaction from "./hooks/useUpdateAnnouncementReaction"
import { Announcement } from "../../types"
import { lightTheme } from "../../constants/theme"

type Params = {
  organizationId?: string
  announcementId?: string
}

const emojiOptions = [
  "❤️",
  "👍",
  "👎",
  "😂",
  "🎉",
  "🔥",
  "😦",
  "😡",
  "🍻",
  "💅",
]

const AnnouncementDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  const announcementId = Number(params.announcementId)
  const { updateAnnouncementReaction, announcement: updatedAnnouncement } =
    useUpdateAnnouncementReaction()
  const {
    getAnnouncement,
    announcement: announcementData,
    loading: announcementLoading,
    error: announcementError,
  } = useGetAnnouncementById()

  const [announcement, setAnnouncement] = useState<Announcement>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (announcementData) {
      setAnnouncement(announcementData)
    }
  }, [announcementData])

  useEffect(() => {
    if (updatedAnnouncement) {
      setAnnouncement(updatedAnnouncement)
    }
  }, [updatedAnnouncement])

  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const navigate = useNavigate()
  const { hasScope } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      await getAnnouncement(Number(organizationId), announcementId)
    }

    logEvent("announcement_details_viewed", {
      id: announcementId,
    })

    fetchData()
  }, [])

  if (announcementLoading) {
    return (
      <ScreenContainer requiresScope={scopes.organization.announcement.read}>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!announcement) {
    return (
      <ScreenContainer
        title={"Announcement not found"}
        requiresScope={scopes.organization.announcement.read}
      ></ScreenContainer>
    )
  }

  const handleAddReactionClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleEmojiSelect = async (emoji: string) => {
    setAnchorEl(null)
    await updateAnnouncementReaction(organizationId, announcement.id, emoji)
  }

  const handleReactionClick = async (emoji: string) => {
    const currentReaction = announcement.reactions.find(
      (reaction) => reaction.current_member_reacted
    )

    if (currentReaction?.reaction === emoji) {
      // Deselect the current reaction
      await updateAnnouncementReaction(organizationId, announcement.id, null)
      logEvent("announcement_reaction_updated", {
        id: announcement.id,
        reaction: null,
      })
    } else {
      // Select the new reaction
      await updateAnnouncementReaction(organizationId, announcement.id, emoji)
      logEvent("announcement_reaction_updated", {
        id: announcement.id,
        reaction: emoji,
      })
    }
  }

  const isPopoverOpen = Boolean(anchorEl)

  const formatMessage = (message: string) => {
    // Replace \n with <br />
    let formattedMessage = message.replace(/\n/g, "<br />")

    // Convert URLs to clickable links
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
    formattedMessage = formattedMessage.replace(urlRegex, (url: string) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); event.preventDefault(); window.open('${url}', '_blank');">${url}</a>`
    })

    // Convert markdown for bold, italic, and both
    const boldItalicRegex = /\*\*\*(.*?)\*\*\*/g
    const boldRegex = /\*\*(.*?)\*\*/g
    const italicRegex = /\*(.*?)\*/g

    formattedMessage = formattedMessage.replace(
      boldItalicRegex,
      "<strong><em>$1</em></strong>"
    )
    formattedMessage = formattedMessage.replace(
      boldRegex,
      "<strong>$1</strong>"
    )
    formattedMessage = formattedMessage.replace(italicRegex, "<em>$1</em>")

    return formattedMessage
  }

  return (
    <ScreenContainer requiresScope={scopes.organization.announcement.read}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginRight: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate(`/organizations/${organizationId}/dashboard`)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Box>
        <Box className={styles.name}>
          {announcement?.title}{" "}
          {hasScope(scopes.organization.announcement.views.read) && (
            <IconButton
              onClick={() => {
                logEvent("announcement_views_clicked", {
                  id: announcementId,
                })
                navigate(
                  `/organizations/${organizationId}/announcements/${announcementId}/views`
                )
              }}
            >
              <FontAwesomeIcon icon={icons.eye} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <Avatar
          alt={announcement.author.name}
          src={`${announcement.author.profile_photo}`}
          sx={{ marginRight: 2 }}
        />
        <p style={{ margin: 0 }}>
          {announcement.author.name} -{" "}
          {new Date(announcement.created_at).toLocaleString()}
        </p>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        style={{
          marginTop: 0,
          marginBottom: 10,
        }}
      >
        {announcement.reactions.map((reaction, index) => (
          <Tooltip
            key={index}
            title={
              reaction.members.length > 0 ? (
                <Box>
                  {reaction.members
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((member) => (
                      <div key={member.id}>{member.name}</div>
                    ))}
                </Box>
              ) : (
                "No reactions yet"
              )
            }
            arrow
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={1}
              py={0.5}
              borderRadius="12px"
              bgcolor="#f3f3f3"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleReactionClick(reaction.reaction)
              }}
              style={{
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "bold",
                boxSizing: "border-box",
                outline: reaction.current_member_reacted
                  ? `solid 1px ${lightTheme.palette.primary.main}`
                  : "none",
                outlineOffset: "-1px",
              }}
            >
              <span style={{ marginRight: 4 }}>{reaction.reaction}</span>
              <span>{reaction.count}</span>
            </Box>
          </Tooltip>
        ))}
        {/* Blank button for adding new reaction */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={1}
          py={0.5}
          borderRadius="12px"
          bgcolor="#f3f3f3"
          style={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "bold",
            border: `dashed 1px ${lightTheme.palette.primary.main}`,
            color: lightTheme.palette.primary.main,
          }}
          onClick={handleAddReactionClick}
        >
          <FontAwesomeIcon
            icon={icons.addEmoji}
            fontSize={16}
            style={{ paddingTop: 2, paddingBottom: 2 }}
          />
        </Box>
      </Box>
      <Box>
        <div>
          <p
            dangerouslySetInnerHTML={{
              __html: formatMessage(announcement.message),
            }}
          />
        </div>
      </Box>
      {/* Bubble Popover for emoji selection */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            borderRadius: "20px",
            padding: "10px",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {emojiOptions.map((emoji, index) => (
          <Box
            key={index}
            onClick={() => handleEmojiSelect(emoji)}
            sx={{
              fontSize: "24px",
              cursor: "pointer",
              margin: "5px",
              padding: "5px",
              borderRadius: "4px",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#f3f3f3",
              },
            }}
          >
            {emoji}
          </Box>
        ))}
      </Popover>
    </ScreenContainer>
  )
}

export default AnnouncementDetailsScreen
