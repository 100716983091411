import React from "react"
import { ListItem, Box, IconButton, Typography } from "@mui/material"
import styles from "./GuestListDetail.module.css"
import { lightTheme } from "../../../constants/theme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/pro-regular-svg-icons"

const GuestListDetail = ({ member, props }: { member: any; props: any }) => {
  return (
    <ListItem
      key={member.id}
      className={styles.container}
      sx={{
        border: `solid 1px ${lightTheme.palette.secondary.main}`,
      }}
    >
      <Box>
        <Typography className={styles.memberName}>
          {member.user.name}
        </Typography>
      </Box>
      {member.guests.length > 0 ? (
        <Box className={styles.guestsContainer}>
          {member.guests.map(
            (
              guest: (typeof member.guests)[number],
              index: number,
              array: (typeof member.guests)[]
            ) => (
              <Box
                key={guest.id}
                className={styles.singleGuestContainer}
                sx={{ backgroundColor: lightTheme.palette.secondary.main }}
              >
                <Typography variant="body2">{guest.name}</Typography>
                {member.user.id === props.currentUserId &&
                  props.editingEnabled && (
                    <IconButton
                      onClick={() => props.handleDeleteGuest(guest.id)}
                      size="large"
                      sx={{ padding: 0, marginLeft: 1, marginRight: 0.5 }}
                    >
                      <FontAwesomeIcon icon={faX} width={5} />
                    </IconButton>
                  )}
              </Box>
            )
          )}
        </Box>
      ) : (
        "No guests for this member"
      )}
    </ListItem>
  )
}

export default GuestListDetail
