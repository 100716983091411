import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Announcement } from "../../../types"
import { CardContent, Typography, Box, Tooltip, Popover } from "@mui/material"
import { lightTheme } from "../../../constants/theme"
import useUpdateAnnouncementReaction from "../../Announcements/hooks/useUpdateAnnouncementReaction"
import { logEvent } from "../../../utils/analytics-service"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import icons from "../../../assets/Icons/icons"

const emojiOptions = [
  "❤️",
  "👍",
  "👎",
  "😂",
  "🎉",
  "🔥",
  "😦",
  "😡",
  "🍻",
  "💅",
]

const AnnouncementCard = ({
  announcement: announcementData,
  organizationId,
}: {
  announcement: Announcement
  organizationId: number
}) => {
  const navigate = useNavigate()
  const { updateAnnouncementReaction, announcement: updatedAnnouncement } =
    useUpdateAnnouncementReaction()

  const [announcement, setAnnouncement] =
    useState<Announcement>(announcementData)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (updatedAnnouncement) {
      setAnnouncement(updatedAnnouncement)
    }
  }, [updatedAnnouncement])

  const handleAddReactionClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleEmojiSelect = async (emoji: string) => {
    setAnchorEl(null)
    await updateAnnouncementReaction(organizationId, announcement.id, emoji)
  }

  const handleReactionClick = async (emoji: string) => {
    const currentReaction = announcement.reactions.find(
      (reaction) => reaction.current_member_reacted
    )

    if (currentReaction?.reaction === emoji) {
      // Deselect the current reaction
      await updateAnnouncementReaction(organizationId, announcement.id, null)
      logEvent("announcement_reaction_updated", {
        id: announcement.id,
        reaction: null,
      })
    } else {
      // Select the new reaction
      await updateAnnouncementReaction(organizationId, announcement.id, emoji)
      logEvent("announcement_reaction_updated", {
        id: announcement.id,
        reaction: emoji,
      })
    }
  }

  const isPopoverOpen = Boolean(anchorEl)

  const formatMessage = (message: string) => {
    // Replace \n with <br />
    let formattedMessage = message.replace(/\n/g, "<br />")

    // Convert URLs to clickable links
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
    formattedMessage = formattedMessage.replace(urlRegex, (url: string) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); event.preventDefault(); window.open('${url}', '_blank');">${url}</a>`
    })

    // Convert markdown for bold, italic, and both
    const boldItalicRegex = /\*\*\*(.*?)\*\*\*/g
    const boldRegex = /\*\*(.*?)\*\*/g
    const italicRegex = /\*(.*?)\*/g

    formattedMessage = formattedMessage.replace(
      boldItalicRegex,
      "<strong><em>$1</em></strong>"
    )
    formattedMessage = formattedMessage.replace(
      boldRegex,
      "<strong>$1</strong>"
    )
    formattedMessage = formattedMessage.replace(italicRegex, "<em>$1</em>")

    return formattedMessage
  }

  return (
    <>
      <div
        style={{
          marginTop: 10,
          position: "relative",
          boxShadow: "none",
          borderRadius: "none",
          borderBottom: `solid 1px ${lightTheme.palette.secondary.main}`,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(
            `/organizations/${organizationId}/announcements/${announcement.id}`
          )
        }}
      >
        <CardContent>
          <Typography variant="h6">
            <b>{announcement.title}</b>
          </Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: formatMessage(announcement.message),
            }}
          />
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            style={{
              marginTop: 10,
            }}
          >
            {announcement.reactions.map((reaction, index) => (
              <Tooltip
                key={index}
                title={
                  reaction.members.length > 0 ? (
                    <Box>
                      {reaction.members
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((member) => (
                          <div key={member.id}>{member.name}</div>
                        ))}
                    </Box>
                  ) : (
                    "No reactions yet"
                  )
                }
                arrow
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  px={1}
                  py={0.5}
                  borderRadius="12px"
                  bgcolor="#f3f3f3"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleReactionClick(reaction.reaction)
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "bold",
                    boxSizing: "border-box",
                    outline: reaction.current_member_reacted
                      ? `solid 1px ${lightTheme.palette.primary.main}`
                      : "none",
                    outlineOffset: "-1px",
                  }}
                >
                  <span style={{ marginRight: 4 }}>{reaction.reaction}</span>
                  <span>{reaction.count}</span>
                </Box>
              </Tooltip>
            ))}
            {/* Blank button for adding new reaction */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={1}
              py={0.5}
              borderRadius="12px"
              bgcolor="#f3f3f3"
              style={{
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "bold",
                border: `dashed 1px ${lightTheme.palette.primary.main}`,
                color: lightTheme.palette.primary.main,
              }}
              onClick={handleAddReactionClick}
            >
              <FontAwesomeIcon
                icon={icons.addEmoji}
                fontSize={16}
                style={{ paddingTop: 2, paddingBottom: 2 }}
              />
            </Box>
          </Box>
        </CardContent>
      </div>

      {/* Bubble Popover for emoji selection */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            borderRadius: "20px",
            padding: "10px",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {emojiOptions.map((emoji, index) => (
          <Box
            key={index}
            onClick={() => handleEmojiSelect(emoji)}
            sx={{
              fontSize: "24px",
              cursor: "pointer",
              margin: "5px",
              padding: "5px",
              borderRadius: "4px",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#f3f3f3",
              },
            }}
          >
            {emoji}
          </Box>
        ))}
      </Popover>
    </>
  )
}

export default AnnouncementCard
