import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import useGetDashboard from "./hooks/useGetDashboard"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import { styled } from "@mui/system"
import EditIcon from "@mui/icons-material/Edit"
import styles from "./DashboardScreen.module.css"
import { lightTheme } from "../../constants/theme"
import PollCard from "./components/PollCard"
import { logEvent } from "../../utils/analytics-service"
import AnnouncementCard from "./components/AnnouncementCard"

type Params = {
  organizationId?: string
}

const DashboardScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)

  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const { hasScope } = useAuth()

  const {
    getHouseById,
    house,
    loading: organizationLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    getOrganizationDashboard,
    dashboard,
    loading: dashboardLoading,
  } = useGetDashboard()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getHouseById(Number(organizationId))
        await getOrganizationDashboard(Number(organizationId))

        if (house) {
          localStorage.setItem("activeOrganization", JSON.stringify(house))
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
  }, [])

  if (organizationLoading || dashboardLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  // Define custom progress bars
  const YellowProgressBar = styled(LinearProgress)({
    "& .MuiLinearProgress-bar": {
      backgroundColor: "orange",
    },
  })

  const GreenProgressBar = styled(LinearProgress)({
    "& .MuiLinearProgress-bar": {
      backgroundColor: "green",
    },
  })

  const formatCompletion = (text: string) => {
    return text.replace(
      /\*\*(.*?)\*\*/g,
      (match, p1) => `<strong>${p1}</strong>`
    )
  }

  return (
    <ScreenContainer>
      <Box className={styles.titleContainer}>
        <Typography variant="h3" className={styles.title}>
          {house?.name}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {dashboard?.billing?.alerts && dashboard.billing.alerts.length > 0 && (
          <Grid item xs={12}>
            {dashboard.billing.alerts.map((alert) => (
              <Box
                style={{
                  border: `solid 2px ${lightTheme.palette.secondary.main}`,
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <Box mb="auto">
                  <Typography variant="h6">
                    <b>{alert.title}</b>
                  </Typography>
                  <Typography variant="body2">{alert.message}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Button
                    href={alert.cta.link}
                    style={{
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    {alert.cta.label}
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
        )}
        {dashboard?.announcements && dashboard.announcements.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              className={styles.announcementsContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5" sx={{ marginBottom: "0px" }}>
                <b>Announcements</b>
              </Typography>
              {dashboard.announcements.map((announcement) => (
                <Box width="100%">
                  <AnnouncementCard
                    announcement={announcement}
                    organizationId={organizationId}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        )}
        {dashboard?.guest_lists && dashboard.guest_lists.length > 0 && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              className={styles.guestListContainer}
              sx={{
                border: `solid 2px ${lightTheme.palette.secondary.main}`,
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                <b>Guest Lists</b>
              </Typography>
              {dashboard.guest_lists.map((guestList) => (
                <Box
                  className={styles.guestListSingleContainer}
                  sx={{
                    borderBottom: `solid 1px ${lightTheme.palette.secondary.main}`,
                  }}
                >
                  <Box className={styles.nameAndGuestsContainer}>
                    <Typography variant="h5" className={styles.guestListTitle}>
                      <b>{guestList.name}</b>
                      {hasScope(scopes.organization.guestList.guests.create) &&
                      guestList.max_guests > guestList.guests.length ? (
                        <IconButton
                          onClick={() => {
                            logEvent("dashboard_guest_card_clicked", {
                              id: guestList.id,
                            })
                            navigate(
                              `/organizations/${organizationId}/guest-lists/${guestList.id}?addGuest=true`
                            )
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      ) : null}
                    </Typography>
                    <Box className={styles.guestsContainer}>
                      {guestList.guests.length === 0 && (
                        <p>
                          You haven't added any guests to this guest list yet
                        </p>
                      )}
                      {guestList.guests.map((guest, index) => {
                        if (index < 2) {
                          return (
                            <div key={index} style={{ marginTop: 2 }}>
                              <div>
                                <p style={{ marginTop: 10, marginLeft: 5 }}>
                                  <b>{guest.name}</b>
                                  {guestList.guests.length === 2 &&
                                    `${index === 0 ? " and" : ""}`}
                                  {guestList.guests.length > 2 &&
                                    `${index === 0 ? ", " : ""} ${
                                      index === 1
                                        ? `and ${
                                            guestList.guests.length - 2
                                          } others`
                                        : ""
                                    }`}
                                </p>
                              </div>
                            </div>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Box>
                  </Box>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() =>
                      navigate(
                        `/organizations/${organizationId}/guest-lists/${guestList.id}`
                      )
                    }
                  >
                    View all guests
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
        )}
        {dashboard?.study_hours && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              className={styles.studyHoursContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5">
                <b>Study Hours</b>
              </Typography>
              <Box className={styles.studyHoursBar}>
                {dashboard.study_hours.percentage < 80 && (
                  <LinearProgress
                    variant="determinate"
                    value={dashboard.study_hours.percentage}
                  />
                )}
                {dashboard.study_hours.percentage >= 80 &&
                  dashboard.study_hours.percentage < 100 && (
                    <YellowProgressBar
                      variant="determinate"
                      value={dashboard.study_hours.percentage}
                    />
                  )}
                {dashboard.study_hours.percentage >= 100 && (
                  <GreenProgressBar
                    variant="determinate"
                    value={dashboard.study_hours.percentage}
                  />
                )}
                <Typography sx={{ marginTop: "5px" }}>
                  {dashboard.study_hours.percentage}% complete
                </Typography>
              </Box>
              <Typography sx={{ textAlign: "center" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatCompletion(
                      dashboard.study_hours.formatted_completion
                    ),
                  }}
                />
              </Typography>
            </Box>
          </Grid>
        )}
        {dashboard?.polls &&
          dashboard.polls.map((poll) => (
            <PollCard poll={poll} organizationId={organizationId} />
          ))}
        {dashboard?.members && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              className={styles.membersContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5">
                <b>Members</b>
                {hasScope(scopes.organization.inviteCodes.create) && (
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/organizations/${organizationId}/members?inviteOpen=true`
                      )
                    }
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Typography>
              <Box className={styles.membersInnerContainer}>
                {dashboard.members.map((houseMember) => (
                  <div style={{ marginTop: 2 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Avatar
                        alt={houseMember.user.name}
                        src={`${houseMember.user.profile_photo}`}
                      />
                      <p style={{ marginTop: 10, marginLeft: 5 }}>
                        {houseMember.user.name}
                      </p>
                    </div>
                  </div>
                ))}
              </Box>
              {dashboard.members_count > 5 && (
                <Button
                  style={{ textTransform: "none" }}
                  onClick={() =>
                    navigate(`/organizations/${organizationId}/members`)
                  }
                >
                  View all
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </ScreenContainer>
  )
}

export default DashboardScreen
