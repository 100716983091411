import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { AttendanceItem } from "../../../types"
import AttendanceItemRow from "./AttendanceItemRow"

interface Props {
  attendanceItems: AttendanceItem[]
  organizationId: number
  paginationData: PaginationData
}

const getRows = (attendanceItems: AttendanceItem[], organizationId: number) => {
  return attendanceItems.map((attendanceItem, index) => {
    return (
      <AttendanceItemRow
        key={index}
        attendanceItem={attendanceItem}
        organizationId={organizationId}
      />
    )
  })
}

const AttendanceItemTable = ({
  attendanceItems,
  paginationData,
  organizationId,
}: Props) => {
  const columns: string[] = ["Title", "Created"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(attendanceItems, organizationId)}
      paginationOptions={paginationData}
    />
  )
}

export default AttendanceItemTable
