import React from "react"
import { Poll } from "../../../types"
import { Card, CardContent, Grid, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box } from "@mui/system"
import icons from "../../../assets/Icons/icons"
import { useNavigate } from "react-router-dom"
import { lightTheme } from "../../../constants/theme"
import { logEvent } from "../../../utils/analytics-service"

const PollCard = ({
  poll,
  organizationId,
}: {
  poll: Poll
  organizationId: number
}) => {
  const navigate = useNavigate()

  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
          borderRadius: "10px",
          boxShadow: "none",
          border: `2px solid ${lightTheme.palette.secondary.main}`,
          cursor: "pointer",
        }}
        onClick={() => {
          logEvent("dashboard_poll_card_clicked", { id: poll.id })
          navigate(`/organizations/${organizationId}/polls/${poll.id}`)
        }}
      >
        <CardContent style={{ width: "90%" }}>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}
          >
            {poll.title}
          </Typography>
          {poll.user_has_voted ? (
            poll.choices.map((choice) => (
              <Box key={choice.id}>
                <Typography
                  style={{ fontSize: 12, fontWeight: "bold", color: "#000000" }}
                >
                  {choice.title} ({choice.total_responses}/
                  {poll.total_responses})
                </Typography>
                <Box
                  style={{
                    height: 10,
                    backgroundColor: "#E0E0E0",
                    borderRadius: 5,
                    margin: "10px 0",
                  }}
                >
                  <Box
                    style={{
                      height: "100%",
                      borderRadius: 5,
                      width: `${
                        (choice.total_responses / poll.total_responses) * 100
                      }%`,
                      maxWidth: "100%",
                      backgroundColor: "#ff4444",
                    }}
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <FontAwesomeIcon
                icon={icons.poll}
                style={{ width: "30px", height: "30px" }}
              />
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 5,
                  color: "#000000",
                }}
              >
                You haven't voted yet
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  marginTop: 3,
                  marginBottom: 10,
                  color: "#333333",
                }}
              >
                Click to vote and see poll results
              </Typography>
            </Box>
          )}

          <Box style={{ marginTop: "auto" }}>
            <Typography style={{ fontWeight: "bold" }}>
              Total responses: {poll.total_responses}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PollCard
