import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Box,
} from "@mui/material"
import React, {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useCallback,
} from "react"
import BasicTableCell from "./BasicTableCell"
import BasicTableRow from "./BasicTableRow"
import styles from "./BasicTable.module.css"
import useBreakpoint from "../../../hooks/useBreakpoint"
import { lightTheme } from "../../../constants/theme"

export interface PaginationData {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: any, page: number) => void
  onRowsPerPageChange: (event: any) => void
}

interface BasicTableProps {
  columns: (string | { title: string; action?: ReactNode })[] // Modified type for columns
  rows: ReactNode[] // Changed type for rows to allow custom elements
  paginationOptions?: PaginationData
  ColumnHeader?: ComponentType
  hasHeader?: boolean
}

const BasicTable: FunctionComponent<BasicTableProps> = ({
  columns,
  rows,
  paginationOptions,
  ColumnHeader = BasicTableCell,
  hasHeader,
}) => {
  const breakpoint = useBreakpoint()

  // Handle rendering column headers with optional actions
  const getColumns = useCallback(() => {
    return columns.map((column, index) => {
      const content =
        typeof column === "string" ? (
          column
        ) : (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            {column.title}
            {column.action && column.action}
          </Box>
        )
      return (
        <ColumnHeader
          className={styles.headerCell}
          key={index}
          sx={{
            backgroundColor: `${lightTheme.palette.background.default} !important`,
            color: `${lightTheme.palette.text.primary} !important`,
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {content}
        </ColumnHeader>
      )
    })
  }, [columns, ColumnHeader])

  if (breakpoint === "xxs" || breakpoint === "xs") {
    return (
      <Box className={styles.tableContainer}>
        {paginationOptions && (
          <TablePagination
            count={paginationOptions.count}
            page={paginationOptions.page}
            rowsPerPage={paginationOptions.rowsPerPage}
            onPageChange={paginationOptions.onPageChange}
            onRowsPerPageChange={paginationOptions.onRowsPerPageChange}
            rowsPerPageOptions={[25, 50, 100]}
            labelRowsPerPage="Rows"
          />
        )}
        <TableContainer sx={{ width: "100%" }}>
          <Table sx={{ minWidth: "90%" }} stickyHeader>
            {hasHeader && (
              <TableHead>
                <BasicTableRow>{getColumns()}</BasicTableRow>
              </TableHead>
            )}
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return (
    <Box className={styles.tableContainer}>
      <TableContainer>
        <Table stickyHeader>
          {hasHeader && (
            <TableHead className={styles.tableHead}>
              <BasicTableRow>{getColumns()}</BasicTableRow>
            </TableHead>
          )}
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
      {paginationOptions && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 0,
            marginBottom: -30,
          }}
        >
          <TablePagination
            count={paginationOptions.count}
            page={paginationOptions.page}
            rowsPerPage={paginationOptions.rowsPerPage}
            onPageChange={paginationOptions.onPageChange}
            onRowsPerPageChange={paginationOptions.onRowsPerPageChange}
            rowsPerPageOptions={[25, 50, 100]}
            labelRowsPerPage="Rows"
          />
        </div>
      )}
    </Box>
  )
}

export default BasicTable
