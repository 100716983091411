import {
  Box,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { scopes } from "../../scopes"
import styles from "../GuestLists/GuestListsScreen.module.css"
import useGetAssets from "./hooks/useGetAssets"
import { Asset } from "../../types"
import { Gallery, Image } from "react-grid-gallery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { logEvent } from "../../utils/analytics-service"
import { useParams } from "react-router-dom"

interface Params {
  organizationId?: string
}

const GalleryScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const [index, setIndex] = useState(-1)

  const {
    getAssets,
    paginationData,
    assets,
    loading: assetsLoading,
    error: assetsError,
  } = useGetAssets()

  useEffect(() => {
    const fetchData = async () => {
      await getAssets(Number(organizationId))
    }

    fetchData()
  }, [])

  if (assetsLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  const currentImage = assets[index]
  const nextIndex = (index + 1) % assets.length
  const nextImage = assets[nextIndex] || currentImage
  const prevIndex = (index + assets.length - 1) % assets.length
  const prevImage = assets[prevIndex] || currentImage

  const handleClick = (index: number, item: Image) => {
    logEvent("gallery_item_clicked", { id: item.key })
    setIndex(index)
  }
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  // Transform assets into Image objects
  // @ts-ignore
  const formattedAssets: Image[] = assets.map((asset: Asset) => ({
    src: asset.url,
    thumbnail: asset.url,
    thumbnailWidth: 500,
    thumbnailHeight: 500,
    caption: asset.uploaded_by.name,
  }))

  return (
    <>
      <ScreenContainer requiresScope={scopes.organization.assets.read}>
        <Box
          className={styles.createButtonContainer}
          onClick={() => {
            logEvent("unsupported_feature_clicked", {
              feature: "web_gallery",
            })
            alert(
              "Upload images and videos to the gallery using the Greek Chapter mobile app, available on the App Store and Google Play Store."
            )
          }}
        >
          <AddButton className="className" />
        </Box>
        {assetsError && <ErrorComponent error={assetsError} />}
        {assetsLoading && <CircularProgress />}
        {assets.length > 0 ? (
          <>
            <TablePagination
              component="div"
              count={paginationData?.count || 0}
              page={paginationData?.page}
              onPageChange={paginationData?.onPageChange}
              rowsPerPage={paginationData?.rowsPerPage}
              onRowsPerPageChange={paginationData?.onRowsPerPageChange}
            />
            <Gallery
              images={formattedAssets}
              onClick={handleClick}
              enableImageSelection={false}
            />
            <TablePagination
              component="div"
              count={paginationData?.count || 0}
              page={paginationData?.page}
              onPageChange={paginationData?.onPageChange}
              rowsPerPage={paginationData?.rowsPerPage}
              onRowsPerPageChange={paginationData?.onRowsPerPageChange}
            />
          </>
        ) : (
          <Box
            display="flex"
            width="100%"
            sx={{
              flexDirection: "column",
              mb: "24px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">
              <b>No Images In Gallery</b>
            </Typography>
            <Typography variant="body1">
              Upload images to the organization gallery from the Greek Chapter
              mobile app, available on the App Store and Google Play Store.
            </Typography>
          </Box>
        )}
      </ScreenContainer>
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.url}
          mainSrcThumbnail={currentImage.url}
          nextSrc={nextImage.url}
          nextSrcThumbnail={nextImage.url}
          prevSrc={prevImage.url}
          prevSrcThumbnail={prevImage.url}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          imageTitle={currentImage.uploaded_by.name}
          imageCaption={currentImage.caption}
        />
      )}
    </>
  )
}

export default GalleryScreen
