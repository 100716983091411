export const formatDate = (date: Date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const dt = new Date(date)
  const dtm = dt.getMonth()
  const dtd = dt.getDate()
  return months[dtm] + " " + dtd
}

export const formatTime = (date: Date) => {
  const dt = new Date(date)
  let dtm = dt.getHours()
  let ampm = "AM"
  if (dtm > 12) {
    dtm = dtm - 12
    ampm = "PM"
  }
  const dtd = dt.getMinutes()
  return dtm + ":" + dtd + " " + ampm
}

export const formatYear = (date: Date) => {
  const dt = new Date(date)
  return dt.getFullYear()
}

export const compareDates = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}
