import { Box, CircularProgress, Typography } from "@mui/material"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import useGetAnnouncements from "./hooks/useGetAnnouncements"
import AnnouncementsTable from "./components/AnnouncementsTable"
import AnnouncementDialog from "./components/AnnouncementDialog"
import styles from "./AnnouncementsScreen.module.css"
import { lightTheme } from "../../constants/theme"
import { logEvent } from "../../utils/analytics-service"
import { useParams } from "react-router-dom"

interface Params {
  organizationId?: string
}

const AnnouncementsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const {
    getAnnouncements,
    paginationData,
    announcements,
    loading: announcementsLoading,
    error: announcementError,
  } = useGetAnnouncements()
  const { hasScope } = useAuth()

  const [createAnnouncementOpen, setCreateAnnouncementOpen] = useState(false)
  const [activeFilter, setActiveFilter] = useState("all")

  useEffect(() => {
    const fetchData = async () => {
      await getAnnouncements(Number(organizationId))
    }

    fetchData()
  }, [])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const announcementOpenParam = urlSearchParams.get("createAnnouncementOpen")

    if (
      announcementOpenParam &&
      announcementOpenParam.toLowerCase() === "true"
    ) {
      setCreateAnnouncementOpen(true)
    }
  }, [])

  useEffect(() => {
    if (activeFilter === "all") {
      getAnnouncements(Number(organizationId))
    } else if (activeFilter === "active") {
      getAnnouncements(Number(organizationId), true)
    } else if (activeFilter === "inactive") {
      getAnnouncements(Number(organizationId), false)
    }
  }, [activeFilter])

  const canCreateAnnouncement = hasScope(
    scopes.organization.announcements.create
  )
  const canEdit = hasScope(scopes.organization.announcement.update)

  const activeFilterStyle = {
    color: lightTheme.palette.text.primary,
    fontWeight: "bold",
    "&::after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "2px",
      backgroundColor: lightTheme.palette.primary.main,
      marginTop: "2px",
    },
  }

  const inactiveFilterStyle = {
    color: lightTheme.palette.text.primary,
    opacity: 0.8,
  }

  if (announcementsLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <>
      {canCreateAnnouncement && (
        <Box
          className={styles.createAnnouncementButtonContainer}
          onClick={() => {
            logEvent("announcements_create_announcement_button_clicked")
            setCreateAnnouncementOpen(true)
          }}
        >
          <AddButton className="className" />
        </Box>
      )}
      <ScreenContainer requiresScope={scopes.organization.announcements.create}>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            mb: "24px",
          }}
          className={styles.announcementsScreen}
        >
          {announcementError && <ErrorComponent error={announcementError} />}
          {announcementsLoading && <CircularProgress />}
          <Typography variant="h3" className={styles.title}>
            Announcements
          </Typography>
          <Box className={styles.tableFilterMenu}>
            <button
              className={styles.filterButton}
              onClick={() => setActiveFilter("all")}
            >
              <Typography
                variant="body1"
                sx={
                  activeFilter === "all"
                    ? activeFilterStyle
                    : inactiveFilterStyle
                }
              >
                All
              </Typography>
            </button>
            <button
              className={styles.filterButton}
              onClick={() => setActiveFilter("active")}
            >
              <Typography
                variant="body1"
                sx={
                  activeFilter === "active"
                    ? activeFilterStyle
                    : inactiveFilterStyle
                }
              >
                Active
              </Typography>
            </button>
            <button
              className={styles.filterButton}
              onClick={() => setActiveFilter("inactive")}
            >
              <Typography
                variant="body1"
                sx={
                  activeFilter === "inactive"
                    ? activeFilterStyle
                    : inactiveFilterStyle
                }
              >
                Inactive
              </Typography>
            </button>
          </Box>
          <AnnouncementsTable
            announcements={announcements}
            paginationData={paginationData}
            refreshData={async () => {
              await getAnnouncements(Number(organizationId))
            }}
            organizationId={organizationId}
          />
        </Box>
        <AnnouncementDialog
          isOpen={createAnnouncementOpen}
          close={() => setCreateAnnouncementOpen(false)}
          onSuccess={async () => {
            await getAnnouncements(Number(organizationId))
          }}
          organizationId={organizationId}
        />
      </ScreenContainer>
    </>
  )
}

export default AnnouncementsScreen
