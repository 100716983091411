import React, { FunctionComponent, useEffect } from "react"
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { useParams } from "react-router-dom"
import SettingsIcon from "@mui/icons-material/Settings"
import styles from "../GuestLists/GuestListDetailsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import useGetAttendanceItemById from "./hooks/useGetAttendanceItemById"
import AttendanceMemberRow from "./components/AttendanceMemberRow"

type Params = {
  organizationId?: string
  attendanceItemId?: string
}

const AttendanceItemDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  const attendanceItemId = Number(params.attendanceItemId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const navigate = useNavigate()

  const {
    getAttendanceItem,
    attendanceItem,
    loading: attendanceItemLoading,
    error: attendanceItemError,
  } = useGetAttendanceItemById()

  useEffect(() => {
    const fetchData = async () => {
      await getAttendanceItem(Number(organizationId), attendanceItemId)
    }

    fetchData()
  }, [])

  if (attendanceItemLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!attendanceItem) {
    return (
      <ScreenContainer title={"Attendance item not found"}></ScreenContainer>
    )
  }

  return (
    <ScreenContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginRight: 2,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(`/organizations/${organizationId}/attendance`)
          }
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Box>
        <Box className={styles.name}>
          {attendanceItem?.title}
          {/* <IconButton
            sx={{
              marginLeft: 2,
            }}
            onClick={() => setIsDialogOpen(true)}
          >
            <SettingsIcon />
          </IconButton> */}
        </Box>
      </Box>
      <Box>
        <Table component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Present</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceItem.members.map((member) => (
                <AttendanceMemberRow
                  key={member.id}
                  member={member}
                  attendanceItem={attendanceItem}
                  organizationId={organizationId}
                />
              ))}
            </TableBody>
          </Table>
        </Table>
      </Box>
    </ScreenContainer>
  )
}

export default AttendanceItemDetailsScreen
