import React, { useEffect, useState } from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { OrganizationMember, PointItem } from "../../../types"
import HouseMemberRow from "./MemberPointsRow"
import useGetPointItems from "../hooks/useGetPointItems"
import { PointsResponse } from "../../../types/points"
import useBreakpoint from "../../../hooks/useBreakpoint"
import MobilePointsTable from "./MobilePointsTable"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import useDeletePointItem from "../hooks/useDeletePointItem"
import toast from "react-hot-toast"
import Swal from "sweetalert2"

interface MemberPointsTableProps {
  members: OrganizationMember[]
  paginationData: PaginationData
  refreshData: () => void
  termID: number | undefined
  organizationId: number
}

const MemberPointsTable = ({
  members,
  paginationData,
  refreshData,
  termID,
  organizationId,
}: MemberPointsTableProps) => {
  const [columns, setColumns] = useState<
    (string | { title: string; action?: React.ReactNode })[]
  >(["Name", "Total Points"])
  const breakpoint = useBreakpoint()

  const {
    getPointItems,
    pointItems,
    error: pointItemsError,
  } = useGetPointItems()

  const { deletePointItem, error: deletePointItemError } = useDeletePointItem()

  const handleDeletePointItem = async (item: PointItem) => {
    Swal.fire({
      title: `${item.title}`,
      text: `Are you sure you'd like to delete this point item? `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete Point Item",
      cancelButtonText: "Cancel",
    })
      .then(async (result) => {
        if (result.isConfirmed && item) {
          await deletePointItem(item.id, organizationId, refreshData)
        } else if (!result.isConfirmed) {
          refreshData()
        }
      })
      .catch(() => {
        console.error(`Error deleting point item: ${deletePointItemError}`)
        toast.error(`${deletePointItemError}`)
      })
  }

  const getRows = (
    members: OrganizationMember[],
    refreshData: () => void,
    organizationId: number,
    pointItems?: PointsResponse
  ) => {
    return members.map((member, index) => (
      <HouseMemberRow
        isMobile={false}
        key={index}
        member={member}
        pointItems={pointItems}
        columns={columns.map((col) =>
          typeof col === "string" ? col : col.title
        )}
        refreshData={refreshData}
        organizationId={organizationId}
      />
    ))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activeOrganizationId = Number(
          localStorage.getItem("activeOrganizationId")
        )
        if (activeOrganizationId) {
          const response = await getPointItems(activeOrganizationId, termID)
          if (response) {
            const newColumns = [
              "Name",
              "Total Points",
              ...response.items.map((item) => ({
                title: item.title,
                action: (
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleDeletePointItem(item as unknown as PointItem)
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                ),
              })),
            ]
            setColumns(newColumns)
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${pointItemsError}`)
      }
    }

    fetchData()
  }, [members])

  if (breakpoint === "lg" || breakpoint === "xl") {
    return (
      <BasicTable
        columns={columns}
        rows={getRows(members, refreshData, organizationId, pointItems)}
        paginationOptions={paginationData}
        hasHeader={true}
      />
    )
  } else {
    return (
      <MobilePointsTable
        categories={columns.map((col) =>
          typeof col === "string" ? col : col.title
        )}
        refreshData={refreshData}
      />
    )
  }
}

export default MemberPointsTable
