import { Box, CircularProgress } from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import ScreenContainer from "../components/shared/ScreenContainer"
import useGetMyHouses from "./Users/hooks/useGetMyHouses"
import { useNavigate } from "react-router-dom"

const RouterScreen: FunctionComponent = () => {
  const { getMyHouses, houses: organizations, error } = useGetMyHouses()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (localStorage.getItem("activeOrganizationId") !== null) {
          navigate(
            `/organizations/${localStorage.getItem(
              "activeOrganizationId"
            )}/dashboard`
          )
        }

        const orgs = await getMyHouses()

        if (orgs) {
          if (orgs.length === 0) {
            navigate("/welcome")
          }

          if (
            orgs.length > 0 &&
            localStorage.getItem("activeOrganizationId") === null
          ) {
            localStorage.setItem("activeOrganizationId", `${orgs[0].id}`)
            localStorage.setItem("activeOrganization", JSON.stringify(orgs[0]))
            navigate(`/organizations/${orgs[0].id}/dashboard`)
          } else if (
            orgs.length > 0 &&
            localStorage.getItem("activeOrganizationId") !== null
          ) {
            navigate(
              `/organizations/${localStorage.getItem(
                "activeOrganizationId"
              )}/dashboard`
            )
          }
        }
      } catch (err) {
        console.error(`Error loading organizations: ${error}`)
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer>
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <CircularProgress />
      </Box>
    </ScreenContainer>
  )
}

export default RouterScreen
