import React, { FunctionComponent } from "react"
import Router from "./components/navigation/Router"
import { AuthProvider } from "./services/auth-service"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DayjsUtils from "@date-io/dayjs"
import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { lightTheme } from "./constants/theme"
import { BrowserRouter } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { MenuProvider } from "./components/shared/MenuContext"
import { SidebarProvider } from "./components/shared/shadcn/Sidebar"

const App: FunctionComponent = () => (
  <MenuProvider>
    <BrowserRouter>
      <ThemeProvider theme={lightTheme}>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <SidebarProvider>
                <Router />
                <Toaster />
              </SidebarProvider>
            </MuiPickersUtilsProvider>
          </LocalizationProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </MenuProvider>
)

export default App
