import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Announcement } from "../../../types"
import { Avatar, Button, Tooltip, Box } from "@mui/material"
import AnnouncementDialog from "./AnnouncementDialog"
import styles from "./AnnouncementRow.module.css"
import { lightTheme } from "../../../constants/theme"
import ActiveButton from "./ActiveButton"
import {
  compareDates,
  formatDate,
  formatTime,
  formatYear,
} from "../../../utils/formatters/format-date"

type Props = {
  announcement: Announcement
  refreshData: () => void
  organizationId: number
}

const AnnouncementRow: FunctionComponent<Props> = ({
  announcement,
  refreshData,
  organizationId,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <BasicTableRow
        className={styles.row}
        onClick={() => setDialogOpen(true)}
        key={announcement.id}
        sx={{
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: lightTheme.palette.secondary.main,
            cursor: "pointer",
          },
        }}
      >
        <BasicTableCell component="th" scope="row">
          <Box
            className={styles.createdAt}
            sx={{ color: lightTheme.palette.text.primary }}
          >
            <Box sx={{ marginBottom: "10px" }}>
              {compareDates(new Date(announcement.created_at), new Date())
                ? "Today"
                : formatDate(announcement.created_at)}
            </Box>
            <Box>
              {compareDates(new Date(announcement.created_at), new Date())
                ? formatTime(announcement.created_at)
                : formatYear(announcement.created_at)}
            </Box>
          </Box>
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <Box className={styles.body}>
            <Box className={styles.title}>{announcement.title}</Box>
            <Box className={styles.message}>{announcement.message}</Box>
          </Box>
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <ActiveButton isActive={announcement.active} />
        </BasicTableCell>
      </BasicTableRow>
      <AnnouncementDialog
        isOpen={dialogOpen}
        close={() => setDialogOpen(false)}
        announcement={announcement}
        onSuccess={refreshData}
        organizationId={organizationId}
      />
    </>
  )
}

export default AnnouncementRow
