import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { OrganizationMember } from "../../../types"
import StudyHoursRow from "./StudyHoursRow"

interface Props {
  studyHours: OrganizationMember[]
  paginationData: PaginationData
  reloadStudyHours: () => void
  organizationId: number
}

const getRows = (
  studyHours: OrganizationMember[],
  reloadStudyHours: () => void,
  organizationId: number
) => {
  return studyHours.map((studyHour, index) => {
    return (
      <StudyHoursRow
        key={index}
        studyHour={studyHour}
        reloadStudyHours={reloadStudyHours}
        organizationId={organizationId}
      />
    )
  })
}

const StudyHoursTable = ({
  studyHours,
  paginationData,
  reloadStudyHours,
  organizationId,
}: Props) => {
  const columns: string[] = ["", "Name", "Hours", "Actions"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(studyHours, reloadStudyHours, organizationId)}
      paginationOptions={paginationData}
    />
  )
}

export default StudyHoursTable
