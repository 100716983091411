import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useDeleteStudySession = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const [data, setData] = useState<String>()

  const request = useCallback(
    (
      organizationId: number,
      memberId: number,
      sessionId: number
    ): Promise<String> => {
      return makeRequest<String>(
        `${config.apiUrl}/v1/organizations/${organizationId}/members/${memberId}/study-hours/sessions/${sessionId}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer]
  )

  const deleteStudySession = useCallback(
    async (
      organizationId: number,
      memberId: number,
      sessionId: number,
      onSuccess: () => void
    ) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, memberId, sessionId)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    deleteStudySession,
    studySession: data,
  }
}

export default useDeleteStudySession
