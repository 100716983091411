import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { DeadletterEvent } from "../../../types"
import { Button } from "@mui/material"
import useReplayDeadletterEvent from "../hooks/useReplayDeadletterEvent"
import useDeleteDeadletterEvent from "../hooks/useDeleteDeadletterEvent"

type Props = {
  event: DeadletterEvent
}

const DeadletterEventRow: FunctionComponent<Props> = ({ event }) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const bodyLines = JSON.stringify(event.body, null, 2).split("\n")

  const { replayDeadletterEvent, loading: replayLoading } =
    useReplayDeadletterEvent()
  const { deleteDeadletterEvent, loading: deleteLoading } =
    useDeleteDeadletterEvent()

  const handleReplay = async () => {
    await replayDeadletterEvent(event.id, () => setIsHidden(true))
  }

  const handleDelete = async () => {
    await deleteDeadletterEvent(event.id, () => setIsHidden(true))
  }

  if (isHidden) {
    return null
  }

  return (
    <BasicTableRow key={event.id}>
      <BasicTableCell component="th" scope="row">
        {event.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.type}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.request_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <pre>
          {isExpanded
            ? JSON.stringify(event.body, null, 2)
            : bodyLines.slice(0, 5).join("\n")}
        </pre>
        {bodyLines.length > 5 && (
          <button onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Show Less" : "Show More"}
          </button>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button
          onClick={handleReplay}
          disabled={replayLoading || deleteLoading}
        >
          Replay
        </Button>
        <Button
          onClick={handleDelete}
          disabled={replayLoading || deleteLoading}
        >
          Delete
        </Button>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(event.created_at)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default DeadletterEventRow
